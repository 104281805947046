import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import DemoModal from "./DemoModal";
import { PopupButton } from "react-calendly";

function Requirements() {
  const [modal, setModal] = React.useState(false);
  return (
    <>
      {modal && <DemoModal modal={modal} setModal={setModal} />}
      <div className=" bg-gradient-to-b mt-8 from-[#84C441] to-[#65D0CA] px-7 py-4 flex flex-col lg:items-start items-center space-y-4 ">
        <h1 className="text-lg font-semibold text-center text-white lg:text-2xl lg:py-4 lg:self-center">
          How JT can help you fulfill your <br /> job requirements
        </h1>
        <div className="flex items-start space-x-3 lg:ml-[32vw]">
          <FaCheckCircle className="text-white w-7 h-7 " />
          <h1 className="text-sm text-white lg:text-xl ">
            Allocates you the best candidate as per your project requirement
          </h1>
        </div>
        <div className="flex items-start space-x-3 lg:ml-[32vw]  ">
          <FaCheckCircle className="text-white w-7 h-7 " />
          <h1 className="text-sm text-white lg:text-xl ">
            Saves your time by taking care ofeverything during the whole{" "}
            <br className="hidden lg:block" /> hiring process.{" "}
          </h1>
        </div>
        <div className="flex items-start space-x-3 lg:ml-[32vw]">
          <FaCheckCircle className="text-white w-7 h-7 " />
          <h1 className="text-sm text-white lg:text-xl ">
            Conducts thorough backgroundchecks when considering{" "}
            <br className="hidden lg:block" /> potential employees
          </h1>
        </div>
        <div className="flex items-start space-x-3 lg:ml-[32vw]">
          <FaCheckCircle className="text-white w-7 h-7 " />
          <h1 className="text-sm text-white lg:text-xl ">
            Our expertise and market knowledge proves to be priceless{" "}
          </h1>
        </div>
        <div className="flex items-start space-x-3 lg:ml-[32vw]">
          <FaCheckCircle className="text-white w-7 h-7 " />
          <h1 className="text-sm text-white lg:text-xl ">
            Negotiates on behalf of both parties to reach a mutual{" "}
            <br className="hidden lg:block" /> agreement{" "}
          </h1>
        </div>
        <div className="h-3 "></div>
        <button className="bg-white text-[#569C0D] px-4 py-2 rounded-2xl shadow-xl self-center" onClick={()=>setModal(true)}>
          {/* <PopupButton
            url="https://calendly.com/jobsterritory"
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
            rootElement={document.getElementById("root")}
            text="          Request a demo            "
            pageSettings={{
              backgroundColor: "ffffff",
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
              primaryColor: "00a2ff",
              textColor: "4d5055",
            }}
          /> */}
          Request a demo
        </button>
      </div>
    </>
  );
}

export default Requirements;
