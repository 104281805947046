import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Home/Footer";
import FooterDesktop from '../components/Home/FooterDesktop';
import ClientLogos from "../components/Home/ClientLogos";
import MediaQuery from "react-responsive";

import bgImg from "../media/Rectangle 347.png";
import dbImg from "../media/image 40.png";
import reportImg from "../media/image 41.png";
import genderImg from "../media/image 42.png";
import crossImg from "../media/image 43.png";

import rameshImg from "../media/ramesh.png";
import bantyImg from "../media/banty.png";
import sarunImg from "../media/sarun.png";

function AboutUs() {
  return (
    <div className=" font-poppins">
      <div className='fixed w-[100vw] z-[200] bg-white '>
            <Navbar active="about"/>
        </div>
      <div className="flex flex-col items-center pt-14 mx-5 lg:pt-20">
        <h1 className=" text-[#84C441] uppercase font-bold text-3xl text-center tracking-wide">
          WHO WE ARE
        </h1>
        <img src={bgImg} alt="" className="mt-4 lg:mt-8" />
        <h1 className="mx-2 mt-6 leading-7 lg:text-center lg:text-[18px] lg:mx-[20vw] lg:leading-loose">
        "Jobs Territory" collaborates with the right workforce and offers an effective 
        platform for efficient management and talent acquisition.
        </h1>
        {/* <button className=" bg-[#84C441] px-4 rounded-xl text-white py-2 mt-5 lg:hidden">
          Get in touch
        </button> */}
      </div>
      <div className="flex flex-col items-center pb-0 pt-8 lg:pt-8">
        <h1 className=" text-[#23496A] text-3xl font-bold text-center">
          WHAT SETS US <br className=" lg:hidden" /> APART
        </h1>
        <div className=" bg-gradient-to-l from-[#84C441] via-[#74CA86] to-[#65D0CA] w-[100vw] lg:w-full mt-4 lg:flex items-center justify-around px-[16vw] py-14 hidden">
          <div className="flex flex-col items-center">
            <img src={dbImg} alt="" />
            <h1 className="mt-2 text-xl text-white">Massive Database</h1>
          </div>
          <div className="flex flex-col items-center">
            <img src={reportImg} alt="" />
            <h1 className="mt-2 text-xl text-white">Customised Report</h1>
          </div>
          <div className="relative flex flex-col items-center ">
            <img src={crossImg} alt="" className="absolute -mt-9" />
            <img src={genderImg} alt="" className="" />
            <h1 className="mt-2 text-xl text-white">Gender-neutralism</h1>
          </div>
        </div>
        <div className=" bg-gradient-to-l from-[#84C441] via-[#74CA86] to-[#65D0CA] w-screen mt-4 flex flex-col items-center py-4 lg:hidden">
          <img src={dbImg} alt="" />
          <h1 className="mt-2 text-xl text-white">Massive Database</h1>
        </div>
        <div className=" bg-gradient-to-l from-[#84C441] via-[#74CA86] to-[#65D0CA] w-screen mt-8 flex flex-col items-center py-4 lg:hidden">
          <img src={reportImg} alt="" />
          <h1 className="mt-2 text-xl text-white">Customised Report</h1>
        </div>
        <div className=" bg-gradient-to-l relative from-[#84C441] via-[#74CA86] to-[#65D0CA] w-screen mt-8 flex flex-col items-center pb-4 pt-8 lg:hidden">
          <img src={crossImg} alt="" className="absolute -mt-8" />
          <img src={genderImg} alt="" className="" />
          <h1 className="mt-2 text-xl text-white">Gender-neutralism</h1>
        </div>
      </div>
      <h1 className=" text-[#23496A] text-3xl font-bold text-center mb-1 mt-8 px-14">
        MEET OUR LEADERS
      </h1>
      <div className="flex flex-col items-center lg:flex-row">
        <div className="relative flex mx-auto mt-5">
          <div className=" rounded-xl w-[80vw] lg:w-[18vw]  bg-gradient-to-b p-2  from-[#91FFA7] to-[#5FFFF6]">
            <div className=" bg-[#23496A] h-full text-white flex space-y-5 flex-col items-center  rounded-xl p-4  ">
              <img src={rameshImg} alt="" className="mt-4" />
              <h1 className="text-lg font-bold">Ramesh Jayaraman </h1>
              <h1>
                Ramesh has over 16 years of experience in the recruitment
                industry and has led various recruitment teams across multiple
                verticals and geographies. Through his innovative sourcing
                methods, he has worked with top executives on talent
              </h1>
            </div>
          </div>
        </div>
        <div className="relative flex mx-auto mt-10">
          <div className=" rounded-xl w-[80vw] lg:w-[18vw]  bg-gradient-to-b p-2  from-[#91FFA7] to-[#5FFFF6]">
            <div className=" bg-[#23496A] h-full text-white flex space-y-5 flex-col items-center  rounded-xl p-4  ">
              <img src={bantyImg} alt="" className="mt-4" />
              <h1 className="text-lg font-bold">Banty Bansal </h1>
              <h1>
                With a BBM in HR Management and 8+ years of experience in
                education, sales and recruitment, she envisions herself as Jobs
                Territory's 'Preferred Vendor Partner of Choice' for clients,
                with a passion for continuous learning and highly
              </h1>
            </div>
          </div>
        </div>
        <div className="relative flex mx-auto mt-10">
          <div className=" rounded-xl w-[80vw] lg:w-[18vw]  bg-gradient-to-b p-2  from-[#91FFA7] to-[#5FFFF6]">
            <div className=" bg-[#23496A] h-full text-white flex space-y-5 flex-col items-center  rounded-xl p-4  ">
              <img src={sarunImg} alt="" className="mt-4" />
              <h1 className="text-lg font-bold">Sarun Satheesh </h1>
              <h1>
                Sarun has done B.Tech. in Computer Science and has 10+ years of
                experience in Administration & IT Infrastructure. Since the
                inception of Jobs Territory, he has always been a pivotal member
                in growing JT's strength and offering continuous
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10">
      <MediaQuery query="(max-width: 480px)">
        <div className="aboutClients w-[100vw] flex space-y-5 flex-col items-center py-2">
          <h1 className="text-white text-[24px] font-bold ">OUR CLIENTS</h1>
        </div>
        <div className="aliceSm w-[100vw] flex flex-col items-center">
          <div className="mt-4 w-[90vw]">
            <ClientLogos dir='ltr' mid='true'/>
          </div>
          <div className="w-[90vw] mt-0">
            <ClientLogos dir='rtl' mid='false'/>
          </div>
        </div>
        <div className="mt-2">
          <Footer/>
        </div>
      </MediaQuery>
      <MediaQuery query="(min-width: 540px)">
        <div className="aboutClients w-[100vw] flex space-y-5 flex-col items-center py-2">
          <h1 className="text-white text-[36px] font-bold ">OUR CLIENTS</h1>
        </div>
        <div className="aliceLg flex items-center w-[100vw] ml-[2vw] mb-36">
          <ClientLogos dir='ltr' />
        </div>
        <FooterDesktop />
      </MediaQuery>
      </div>
    </div>
  );
}

export default AboutUs;
