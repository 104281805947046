import React,{useState,useEffect} from 'react';
import {Carousel} from 'react-bootstrap';
import axios from 'axios';

const Testimonials=()=>{

  const [data, setdata] = useState([]);
  const testData = async () => {
    const getdata = await axios.get(
      "https://jobsterritorybackend.herokuapp.com/clientTestimonials"
    );
    setdata(getdata.data);
  };
  useEffect(() => {
    testData();
  }, []);

  return (
    <div className='search-carouselBox'>
        <h1 className='search-carouselTitle'>
            They trusted, we<br/> delivered.
        </h1>
        <h2 className='testimonialsHead'>Testimonials</h2>
        <Carousel className="search-carousel">
          {data.map((item) => {
            return (
          <Carousel.Item key={item._id}>
            <div className='search-center' />
            <p className='search-review'>{item.review.length<200?item.review:item.review.substr(0,200)+'...'}</p>
            <p className='search-designation'><span style={{fontWeight: '700'}}>{item.name}
            </span><br/>{item.designation}, {item.company}</p>
            <img src={item.imageUrl} className='clientImage' />
            <img src={item.clientLogo} className='companyLogo' />
            <button className='search-readMore'>READ MORE...</button>
          </Carousel.Item>
          );
          })}
        </Carousel>
    </div>
  )
}

export default Testimonials;