import React, { useState } from "react";
import quesImg from "../assets/quesImg.png";
import ques2 from "../assets/ques2.png";
import ques4 from "../assets/ques4.png";
import ques8 from "../assets/ques8.png";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./questions.css";

function Questions() {
  
  const {state} = useLocation();
  const {objId} = state;

  const navigate = useNavigate();

  const [select, setSelect] = useState(false);
  const [ans, setAns] = useState({first: "",second: "",third: "", fourth: "", fifth: "", sixth: ""});

  const [pageNumber, setPageNumber] = useState(0);

  const firstPage = [
    "Half Time Studying",
    "Round the-clock studying",
    "Recently graduated",
    "On sabbatical",
    "Employed part-time",
    "Round-the-clock working",
    "Seeking Employment",
  ];
  const secondPage = [
    "I live alone",
    "I live in a conjugal/nuclear family",
    " I live in a joint family",
  ];
  const thirdPage = [
    "I live with my parents",
    "I share my space with spouse/partner",
    "I live with my child",
    "I live with my spouse/partner and a child",
    "I live with friends",
    "Others",
  ];
  const fourthPage = [
    "I do everything by myself",
    "Day-time househelp",
    "Full-time househelp",
    "24 hours home assistance/domestic helper",
  ];
  const fifthPage = [
    "Attend Podcasts/Webinar related to my field",
    "Undertake online lessons to improve my skills",
    "Read to stay up to date within my field of work",
    "Review job openings & apply to my area of expertise",
    "Fulfil my professional roles and responsibilities",
    "Cook Lunch",
    "Meditate/Pray",
    "Monitor the work of househelpers",
    "Have my first meal of the day",
    "Make/Cook/Prepare lunches and dinners",
    "Make/Prepare Breakfast",
    "Engage in some physical activity",
    "Leisure Time",
    "Shop for household goods",
  ];
  const sixthPage = [
    "Watch TV",
    "Cook something to munch",
    "Organize my living space/home",
    "Take a siesta/catnap",
  ];
  const seventhPage = [
    "Outing with friends and family",
    "Make/Prepare Dinner",
    "Have/Eat supper",
    "Eat/Have last meal of the day",
    "Spend time with family",
  ];
  const eightPage = ["Call it a day and go to sleep"];

  const style = {
    background: "#FFD7EE",
  };

   const handleOption=(e)=>{
      const {id} = e.target;
      switch(pageNumber){
          case 1: setAns(prev => {
            return {...prev,first: firstPage[id]}});
          break;
          case 2: setAns(prev => {
            return {...prev,second: secondPage[id]}});
          break;
          case 3: setAns(prev => {
            return {...prev,third: thirdPage[id]}});
          break;
          case 4: setAns(prev => {
            return {...prev,fourth: fourthPage[id]}});
          break;
          case 5: setAns(prev => {
            return {...prev,fifth: fifthPage[id]}});
          break;
          case 6: setAns(prev => {
            return {...prev,sixth: sixthPage[id]}});
          break;
      }
    };

    const handleSubmit=()=>{
      axios.patch("https://jobs-territory-api.herokuapp.com/jobsterritory/womensterritory/feedback",{documentId: objId,question1: ans.first,question2: ans.second,question3: ans.third,question4: ans.fourth,
      question5: ans.fifth,question6: ans.sixth}).then(
      ()=>{
        console.log("Success");
      }).catch((err)=>console.log(err));
      setPageNumber(9);
  };

  return (
    <>
      <div className="questionnaire-background">
        {pageNumber === 0 && (
          <>
            <div className="start-box">
              <div className="quesBack">
                <button
                  onClick={() => navigate("/wterritory")}
                  className="quesBackIcon"
                >
                  <svg
                    width="30"
                    height="20"
                    viewBox="0 0 30 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 1L2 9.5M2 9.5L10.5 19M2 9.5H28.5"
                      stroke="#FA54B2"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="start-text">
                <p style={{ color: "#E9158E", textAlign: "center" }}>
                  Take this questionnaire for better assessment of your profile
                </p>
              </div>
              {/* <button className="quesBtn" onClick={() => setPageNumber(1)}>
                Start
              </button> */}
              <div className="quesBtn">
                <button
                  style={{
                    width: "4rem",
                    fontFamily: "Poppins",
                    fontSize: "x-large",
                    fontWeight: "400",
                  }}
                  onClick={() => setPageNumber(1)}
                >
                  <span className="animation">Start</span>
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "end",
              }}
            >
              <img src={quesImg} className="quesImg" />
            </div>
          </>
        )}
        {pageNumber === 1 && (
          <>
            <div className="question-container">
              <div className="question-title">
                What keeps you busy these days?
              </div>
              {/* <div className="btn-group-vertical" role="group"> */}
              {firstPage.map((answer, index) => {
                return (
                  <>
                    <button
                      className="btn answer-button"
                      id={index}
                      key={index}
                      value={answer}
                      // style={select?style:null}
                      onClick={handleOption}
                    >
                      {answer}
                    </button>
                  </>
                );
              })}
              {/* </div> */}
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                className="quesPageIcon"
              >
                <svg
                  width="30"
                  height="20"
                  viewBox="0 0 30 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 1L2 9.5M2 9.5L10.5 19M2 9.5H28.5"
                    stroke="#FA54B2"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <button
                className="nextBtn"
                onClick={() => setPageNumber((prev) => prev + 1)}
              >
                Next
              </button>
            </div>
          </>
        )}
        {pageNumber === 2 && (
          <>
            <div className="question-container">
              <div className="question-title">Who do you call your family?</div>

              {/* <div className="btn-group-vertical" role="group"> */}
              {secondPage.map((answer, index) => {
                return (
                  <>
                    <div>
                      <button
                        className="btn answer-button"
                        key={index}
                        value={answer}
                        id={index}
                        onClick={handleOption}
                      >
                        {answer}
                      </button>
                    </div>
                  </>
                );
              })}
              {/* </div> */}
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                className="quesPageIcon"
              >
                <svg
                  width="30"
                  height="20"
                  viewBox="0 0 30 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 1L2 9.5M2 9.5L10.5 19M2 9.5H28.5"
                    stroke="#FA54B2"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <button
                className="nextBtn"
                onClick={() => setPageNumber((prev) => prev + 1)}
              >
                Next
              </button>
            </div>
            <div style={{ flex: "0.5" }}>
              <img src={ques2} className="quesImg" />
            </div>
          </>
        )}
        {pageNumber === 3 && (
          <>
            <div className="question-container">
              <div className="question-title">
                Your current housing conditions
              </div>
              {/* <div className="btn-group-vertical" role="group"> */}
              {thirdPage.map((answer, index) => {
                return (
                  <>
                    <button
                      className="btn answer-button"
                      key={index}
                      value={answer}
                      id={index}
                      onClick={handleOption}
                    >
                      {answer}
                    </button>
                  </>
                );
              })}
              {/* </div> */}
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                className="quesPageIcon"
              >
                <svg
                  width="30"
                  height="20"
                  viewBox="0 0 30 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 1L2 9.5M2 9.5L10.5 19M2 9.5H28.5"
                    stroke="#FA54B2"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <button
                className="nextBtn"
                onClick={() => setPageNumber((prev) => prev + 1)}
              >
                Next
              </button>
            </div>
          </>
        )}
        {pageNumber === 4 && (
          <>
            <div className="question-container">
              <div className="question-title">
                Who helps/assists you with household/domestic chores?
              </div>
              {/* <div className="btn-group-vertical" role="group"> */}
              {fourthPage.map((answer, index) => {
                return (
                  <>
                    <button
                      className="btn answer-button"
                      key={index}
                      value={answer}
                      id={index}
                      onClick={handleOption}
                    >
                      {answer}
                    </button>
                  </>
                );
              })}
              {/* </div> */}
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                className="quesPageIcon"
              >
                <svg
                  width="30"
                  height="20"
                  viewBox="0 0 30 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 1L2 9.5M2 9.5L10.5 19M2 9.5H28.5"
                    stroke="#FA54B2"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <button
                className="nextBtn"
                onClick={() => setPageNumber((prev) => prev + 1)}
              >
                Next
              </button>
            </div>
            <img
              src={ques4}
              className="quesImg"
              style={{ marginTop: "115px", left: "110px" }}
            />
          </>
        )}
        {pageNumber === 5 && (
          <>
            <div className="question-container">
              <div className="question-title">I carry forward my day with</div>

              {/* <div className="btn-group-vertical" role="group"> */}
              {fifthPage.map((answer, index) => {
                return (
                  <>
                    <button
                      className="btn answer-button"
                      key={index}
                      value={answer}
                      id={index}
                      onClick={handleOption}
                    >
                      {answer}
                    </button>
                  </>
                );
              })}
              {/* </div> */}
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                className="quesPageIcon"
              >
                <svg
                  width="30"
                  height="20"
                  viewBox="0 0 30 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 1L2 9.5M2 9.5L10.5 19M2 9.5H28.5"
                    stroke="#FA54B2"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <button
                className="nextBtn"
                onClick={() => setPageNumber((prev) => prev + 1)}
              >
                Next
              </button>
            </div>
          </>
        )}
        {pageNumber === 6 && (
          <>
            <div className="question-container">
              <div className="question-title">
                As I go about my afternoon, I usually
              </div>
              {/* <div className="btn-group-vertical" role="group"> */}
              {sixthPage.map((answer, index) => {
                return (
                  <>
                    <button
                      className="btn answer-button"
                      key={index}
                      value={answer}
                      id={index}
                      onClick={handleOption}
                    >
                      {answer}
                    </button>
                  </>
                );
              })}
              {/* </div> */}
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                className="quesPageIcon"
              >
                <svg
                  width="30"
                  height="20"
                  viewBox="0 0 30 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 1L2 9.5M2 9.5L10.5 19M2 9.5H28.5"
                    stroke="#FA54B2"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <button
                className="nextBtn"
                onClick={() => setPageNumber((prev) => prev + 1)}
              >
                Next
              </button>
            </div>
          </>
        )}
        {pageNumber === 7 && (
          <>
            <div className="question-container">
              <div className="question-title">
                I dedicate my evening/sundown to{" "}
              </div>
              {/* <div className="btn-group-vertical" role="group"> */}
              {seventhPage.map((answer, index) => {
                return (
                  <>
                    <button
                      className="btn answer-button"
                      key={index}
                      value={answer}
                    >
                      {answer}
                    </button>
                  </>
                );
              })}
              {/* </div> */}
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                className="quesPageIcon"
              >
                <svg
                  width="30"
                  height="20"
                  viewBox="0 0 30 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 1L2 9.5M2 9.5L10.5 19M2 9.5H28.5"
                    stroke="#FA54B2"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <button
                className="nextBtn"
                onClick={() => setPageNumber((prev) => prev + 1)}
              >
                Next
              </button>
            </div>
          </>
        )}
        {pageNumber === 8 && (
          <>
            <div className="question-container">
              <div className="question-title">How I end my day</div>
              {/* <div className="btn-group-vertical" role="group"> */}
              {eightPage.map((answer, index) => {
                return (
                  <>
                    <button
                      className="btn answer-button"
                      key={index}
                      value={answer}
                    >
                      {answer}
                    </button>
                  </>
                );
              })}
              {/* </div> */}
              <button
                className="quesBtn"
                style={{
                  position: "relative",
                  marginTop: "24px",
                  marginBottom: "51px",
                  width: "113px",
                  height: "39px",
                  fontSize: "18px",
                }}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
            <img src={ques8} className="quesImg" />
          </>
        )}
        {pageNumber === 9 && (
          <>
            <div className="start-box" style={{display: 'flex', flexDirection: 'column', alignItems: 'start', textAlign: 'center'}}>
              <button
                  onClick={() => setPageNumber((prev) => prev - 1)}
                  className="mt-2 ml-2"
                >
                  <svg
                    width="30"
                    height="20"
                    viewBox="0 0 30 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 1L2 9.5M2 9.5L10.5 19M2 9.5H28.5"
                      stroke="#FA54B2"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              <div className="text-[24px] ml-[1vw] mt-10">
                Your questionnaire is submitted!!!
                <br /><br/>
                We will contact you soon
              </div>
              <button
                className="rounded-lg ml-[8vw]"
                style={{
                  color: '#FFFFFF',
                  width: "113px",
                  height: "39px",
                  fontSize: "18px",
                  background: '#E9158E',
                  marginTop: "58px",
                }}
                onClick={() => navigate("/wterritory")}
              >
                Done
              </button>
            </div>
            <img src={ques8} className="quesImg" />
          </>
        )}
      </div>
    </>
  );
}

export default Questions;
