import React from "react";

import img from "../media//image 12.png";

function OptUs({ text }) {
  return (
    <div className=" bg-[#23496A] flex flex-col items-center p-3 space-y-2">
      <h1 className="text-lg lg:text-[1.5vw] font-semibold text-white lg:py-4">
        WHY SHOULD YOU OPT FOR IT
      </h1>
      <img src={img} alt="" />
      <h1 className="pt-3 pb-2 mx-2 text-sm text-white lg:mx-[32vw] lg:text-[0.9vw] lg:leading-[24px] lg:text-center lg:w-[752px]">
        {text}
      </h1>
    </div>
  );
}

export default OptUs;
