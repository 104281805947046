import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserView,MobileView } from 'react-device-detect';
import "./App.css";
import AboutUs from "./pages/AboutUs";
import Bceh from "./pages/Bceh";
import BulkHiring from "./pages/BulkHiring";
import ITHiring from "./pages/ITHiring";
import Leadership from "./pages/Leadership";
import Pph from "./pages/Pph";
import RecrServices from "./pages/RecrServices";
import Home from "./components/Home/Home";
import Search from "./components/Search/Search";
import WomensTerritory from "./pages/WomesTerritory";
import ComingSoon from "./pages/ComingSoon";
import Questions from "./pages/Questions";
import QuestionsDesktop from "./pages/QuestionsDesktop";
import ScrollToTop from "./components/ScrollToTop";

function App() {

  return (
    <div className="App">
      <ScrollToTop>
        <Routes>
          <Route path="/services" element={<RecrServices />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/bceh" element={<Bceh />} />
          <Route path="/pph" element={<Pph />} />
          <Route path="/leadership" element={<Leadership />} />
          <Route path="/bulkhiring" element={<BulkHiring />} />
          <Route path="/ithiring" element={<ITHiring />} />
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<Search />} />
          <Route path="/wterritory" element={<WomensTerritory />} />
          <Route path="/comingSoon" element={<ComingSoon/>}/>
          <Route path="/questions" element={<Questions/>}/>
          <Route path="/questionsDesk" element={<QuestionsDesktop/>}/>
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;