import React, { useRef, useState } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import OTPInput from "otp-input-react";
import { authhelper } from "../firebase-config";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import wtForm from '../assets/wtForm.png';
import "./wtdesk.css"

const WtFormDesktop = () => {

  const navigate = useNavigate();
  const [data, setData] = useState({ name: "", phone: "", email: "",notifications: false});
  const [enabled, setEnabled] = useState(true);
  const [OTP, setOTP] = useState("");
  const [visible, setVisible] = useState(false);
  const [OTPVisible, setOTPVisible] = useState(false);

  const phoneRef = useRef();

  const handleChange = (e) => {
    setData(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    });
  };

  const handleNotifications=()=>{
    setData(prev => {
      return { ...prev, notifications: !prev.notifications }
    })
  };

  const generateRecaptchaVerifier = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => { },
      },
      authhelper
    );
  };

  const getOTP = (e) => {
    if (phoneRef.current.value.length === 10) {
      let phoneNumber = "+91" + phoneRef.current.value;
      console.log(phoneNumber);
      generateRecaptchaVerifier();
      let appVerifier = window.recaptchaVerifier;
      setVisible(true);
      setOTPVisible(true);
      signInWithPhoneNumber(authhelper, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setEnabled(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const verifyOTP = () => {
    if (OTP.length === 6) {
      window.confirmationResult
        .confirm(OTP)
        .then((result) => {
          setVisible(false);
          setEnabled(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post("https://jobs-territory-api.herokuapp.com/jobsterritory/womensterritory/feedback",{name: data.name, 
        contact: data.phone, email: data.email,notifications: data.notifications}).then(
        (res)=>{
          console.log(res);
          const objId = res.data.id;
          navigate('/questionsDesk', {state: {objId}} );
          setData({name:"",phone:"",email:"",notifications: false});
        }).catch((err)=>console.log(err));
  };

  return (
    <div className='font-poppins w-[100vw] bg-[#DCC9D4] mb-10'>
      <div className='flex flex-row items-center'>
        <img src={wtForm} className='w-[50vw] h-[87vh]' />
        <form className='bg-white w-[35vw] flex flex-col items-center' onSubmit={handleSubmit}>
          <h1 className='font-semibold text-[1.5vw] mt-4'>Be the next SuperWomen</h1>
          <div className='flex flex-col w-[70%] text-left mt-6 ml-4'>
            <h2 className='text-[0.8vw]'>Your Name *</h2>
            <div className='w-[95%] h-[2.5rem] input-box'>
              <input className='p-0 text-[1vw] ml-1 mt-1 h-5/6 w-11/12 outline-none border-none' name="name" value={data.name} pattern="[a-zA-Z]+" onChange={handleChange} onInvalid={e => e.target.setCustomValidity("Please enter alphabets only.")} onInput={e => e.target.setCustomValidity('')} required  />
            </div>
          </div>
          <div className='flex flex-col w-[70%] text-left mt-4 ml-4'>
            <p className='text-[0.8vw]'>Your Email *</p>
            <div className='w-[95%] h-[2.5rem] input-box'>
              <input type='email' className='p-0 text-[1vw] ml-1 mt-1 h-5/6 ml-1 w-11/12 outline-none border-none' name="email" value={data.email} onChange={handleChange} required />
            </div>
          </div>
          <div className='flex flex-col w-[70%] text-left mt-4 ml-4'>
            <p className='text-[0.8vw]'>Phone Number *</p>
            <div className='w-[95%] h-[2.5rem] input-box'>
              <input type="numeric" value={data.phone} ref={phoneRef} className='p-0 text-[1vw] ml-1 mt-1 h-5/6 ml-1 w-11/12 outline-none border-none' name="phone" onChange={handleChange} required />
            </div>
          </div>
          <div className='flex flex-row mt-4'>
          <div className='flex flex-col items-center'>
            {!OTPVisible ? (
              <button onClick={getOTP} className='bg-[#E9158E] w-[7vw] text-center text-white text-[1vw] rounded-[10px] p-2 '>Get OTP</button>
            ) : (
              ""
            )}
            {visible ? (
              <div className="mt-2 flex flex-col items-center">
                <div className="flex font-extrabold justify-center otp-input-container">
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    inputClassName="rounded-md outline-none border-[1px] border-gray-400 otp-input-class"
                    className=""
                  />
                </div>
                <button
                  type="button"
                  onClick={verifyOTP}
                  className=" text-center text-white p-1.5 px-4 mt-3 bg-[#e9158e] rounded-lg otp-input-button" style={{ fontFamily: "Poppins" }}
                >
                  Verify OTP
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className='flex flex-row items-center mt-10'>
          <input type='checkbox' className='' required/>
          <h2 className='whitespace-nowrap text-[0.9vw] text-[#565656] ml-2'>I agree to Terms & Conditions and Privacy Policy</h2>
        </div>
        <div className='flex flex-row items-center mt-2 mr-[3vw]'>
          <input type='checkbox' className='' onClick={handleNotifications}/>
          <h2 className='whitespace-nowrap text-[0.9vw] text-[#565656] ml-2'>I wish to receive notification on whatsApp</h2>
        </div>
        <button type="submit" className='mt-10  bg-[#E9158E] text-center text-white text-[1.5vw] rounded-[10px] w-[9vw] p-1 mb-8'>NEXT</button>
        <div
          id="recaptcha-container"
          className="flex items-center justify-center"
        ></div>
        </form>
      </div>
    </div>
  )
}

export default WtFormDesktop;