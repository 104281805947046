import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ConfirmModal({ show, close }) {
    console.log(close);
    const [showSignup, setShowSignup] = React.useState(false);
    return (
        <Modal show={show}>
            {
                showSignup === false &&
                <Modal.Body>
                    <p className='modal-text'>Are you sure you want to apply for this job?</p>
                    <button className="btn btn-secondary no-button" onClick={close}>No</button>
                    <button className="btn btn-success yes-button" onClick={() => setShowSignup(true)}>Yes</button>
                </Modal.Body>
            }{
                showSignup === true &&
                <Modal.Body>
                    <button className="btn modal-sign-up btn-success">Sign Up</button>
                </Modal.Body>
            }
        </Modal>
    );
}


export default ConfirmModal