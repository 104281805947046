import React from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import img1 from "../media//image 35.png";
import img2 from "../media//image 36.png";
import img3 from "../media//image 37.png";
import img4 from "../media//image 38.png";
import img5 from "../media//image 39.png";

function RecrServices() {
  let navigate = useNavigate();

  return (
    <div className=" font-poppins">
      <div className="fixed w-[100vw] z-[100] bg-white top-0">
        <Navbar active='recr'/>
      </div>
      <div className="flex flex-col items-center pt-16 mx-5 space-y-4 lg:pt-20 ">
        <h1 className=" text-[#84C441] uppercase font-bold text-2xl lg:text-3xl text-center tracking-wide lg:leading-10">
          Recruitment <br /> Services
        </h1>
        <h1 className="leading-7 lg:pt-6 lg:text-[1vw]  text-center">
          Jobs Territory inculcates technology-driven recruiting services. Through our huge database, 
          we allocate the most suitable candidates. This works best with both startups and large-scale 
          businesses.
        </h1>
        <h1 className="leading-7 lg:pt-4 lg:text-[1vw]  text-center ">
          We are a one-stop for filling an organisation's ongoing need for
          talented and skilled employees throughout the project cycle. 
        </h1>
      </div>
      <div className="flex flex-col items-center pb-4 mx-5 mt-8 space-y-10 lg:justify-center lg:space-x-32 lg:mt-16 lg:space-y-0 lg:flex-row">
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() => {
            navigate("/bceh");
          }}
        >
          <div className=" bg-[#E7F7ED] p-6 rounded-full">
            <img src={img1} alt="" className="" />
          </div>
          <h1 className="mt-2 font-semibold ">Blue-Collar Hiring</h1>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() => {
            navigate("/pph");
          }}
        >
          <div className=" bg-[#FEF6E6] p-6 rounded-full">
            <img src={img2} alt="" className="" />
          </div>
          <h1 className="mt-2 font-semibold ">Non Tech Hiring</h1>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() => {
            navigate("/leadership");
          }}
        >
          <div className=" bg-[#FBF2F3] p-6 rounded-full">
            <img src={img3} alt="" className="" />
          </div>
          <h1 className="mt-2 font-semibold ">Leadership Hiring</h1>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() => {
            navigate("/bulkhiring");
          }}
        >
          <div className=" bg-[#F4F8E9] p-6 rounded-full">
            <img src={img4} alt="" className="" />
          </div>
          <h1 className="mt-2 font-semibold ">Bulk Hiring</h1>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() => {
            navigate("/ithiring");
          }}
        >
          <div className=" bg-[#E9F6F8] p-6 rounded-full">
            <img src={img5} alt="" className="" />
          </div>
          <h1 className="mt-2 font-semibold ">Tech Hiring</h1>
        </div>
      </div>
    </div>
  );
}

export default RecrServices;
