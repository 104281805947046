import React from "react";
import Footer from "../components/Home/Footer";
import FooterDesktop from '../components/Home/FooterDesktop';
import TestimonialsDesktop from '../components/Home/TestimonialsDesktop';
import MediaQuery from "react-responsive";
import ClientLogos from "../components/Home/ClientLogos";
import Testimonials from "../components/Home/Testimonials";
import HypeComp from "../components/HypeComp";
import IndustriesCater from "../components/IndustriesCater";
import Navbar from "../components/Navbar";
import OptUs from "../components/OptUs";
import Requirements from "../components/Requirements";
import img1 from "../media//image 35.png";
import img61 from "../media//image 61.png";
import img62 from "../media//image 62.png";
import img65 from "../media//image 65.png";
import img66 from "../media//image 66.png";

import bgImg from "../media/image 77.png";

function Bceh() {
  return (
    <div className=" font-poppins">
      <div className='fixed w-[100vw] z-[200] bg-white '>
            <Navbar active="recr"/>
        </div>
      <div className=" bg-gradient-to-br from-[#84C441]/30 via-[#74CA86]/30 to-[#65D0CA]/30 h-[40vh] lg:clip-style-2 lg:w-[30vw]  clip-style -z-10"></div>
      <div className="z-10 flex flex-col items-center pt-2 mx-5 -mt-80">
        <div className=" bg-[#EBFFD6] p-6 rounded-full mt-14 z-10 lg:mt-24">
          <img src={img1} alt="" className="z-10" />
        </div>
        <h1 className="mt-3 font-bold text-xl text-[#23496A] z-10 ">
          Blue-Collar Hiring
        </h1>
        <img
          src={bgImg}
          alt=""
          className="z-10 mx-10 mt-[2vw] shadow-md shadow-gray-600"
        />
        <h1 className="mt-4 text-sm lg:text-center lg:mt-7 lg:text-[0.9vw] lg:leading-[24px] lg:text-base lg:mx-[30vw]">
          Applicants applying for blue-collar jobs usually come with unformatted
          & undetailed resumes. Thus, making it difficult for you to make a
          decision. The situation has improved through initiatives such as Make
          in India, Digital India, Startup India, and Skill India. Taking it
          forward, we have developed a Blue-Collar recruiting service to
          eliminate middlemen and agency hassle.
        </h1>
      </div>
      <div className="pb-2 ">
        <Requirements />
      </div>
      <div className="py-4 ">
        <OptUs text="The professions' labor markets are wrecked up with plenty of jobs with the shifty talent to fill. Hiring blue-collar workers might be a tedious task, thus making it easy by opting for blue-collar employee hiring services with the help of the right recruitment agency. Thence, ensuring you a proper operation of the business." />
      </div>
      <h1 className=" text-[#84C441] text-2xl text-center font-bold tracking-wide mt-3 ">
        What's the hype behind Jobs Territory's Blue Collar Hiring?
      </h1>
      <div className="flex flex-col items-center px-4 py-2 lg:flex-row lg:space-x-36 lg:justify-center">
        <div className="flex items-center mx-5 mt-0 space-x-5 lg:space-x-36">
          <HypeComp img={img61} text="Have everything and everyone you need" />
          <HypeComp
            img={img62}
            text="Moulds as per your specific requirements."
          />
        </div>
        <div className="flex items-center mx-5 mt-0 space-x-5 lg:space-x-36">
          <HypeComp
            img={img65}
            text="Enhance employee’s skills to make them more qualified"
          />
          <HypeComp
            img={img66}
            text="Whenever you are scouting, we will offer 
            you exclusive talents
            "
          />
        </div>
      </div>
      <IndustriesCater />
      <div className="mt-10">
      <MediaQuery query="(max-width: 480px)">
        <div className="bg-gradient-to-l from-[#84C441] via-[#74CA86] to-[#65D0CA] w-[100vw] flex space-y-5 flex-col items-center py-2">
          <h1 className="text-white text-2xl font-bold">OUR CLIENTS</h1>
        </div>
        <div className="aliceSm w-[100vw] flex flex-col items-center">
          <div className="w-[90vw] mt-3">
            <ClientLogos dir='ltr' mid='true'/>
          </div>
          <div className="w-[90vw] mt-3">
            <ClientLogos dir='rtl' mid='false'/>
          </div>
        </div>
        <div className="mt-3">
            <Testimonials />
        </div>
        <div className="mt-10">
            <Footer/>
        </div>
      </MediaQuery>
      <MediaQuery query="(min-width: 540px)">
        <div className="aboutClients w-[100vw] flex space-y-5 flex-col items-center py-2">
          <h1 className="text-white text-[36px] font-bold ">OUR CLIENTS</h1>
        </div>
        <div className="aliceLg flex items-center w-[100vw] ml-[2vw]">
          <ClientLogos dir='ltr' />
        </div>
        <div className="mt-[14vh]">
          <TestimonialsDesktop />
        </div>
        <div className="mt-[8vh]">
          <FooterDesktop />
        </div>
      </MediaQuery>
      </div>
    </div>
  );
}

export default Bceh;
