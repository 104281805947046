import React from 'react';

const Form= ()=>{
  return (
    <form className='search-form'>
        <div className='search-formTitle'>
            Got To Say Something?<br/>
            <span style={{fontWeight: '300', fontSize: '20px'}}>
            We are listening...</span>
        </div>
        <h1 className='field1Title'>Your Name *</h1>
        <div className='field1'>
            <input className='field1Text' />
        </div>
        <h1 className='field2Title'>Organization *</h1>
        <div className='field2'>
            <input className='field1Text' />
        </div>
        <h1 className='field3Title'>Phone Number *</h1>
        <div className='field3'>
            <input className='field1Text' />
        </div>
        <h1 className='field4Title'>Email Id *</h1>
        <div className='field4'>
            <input type='email' className='field1Text' />
        </div>
        <h1 className='field5Title'>Your Message *</h1>
        <div className='field5'>
            <textarea className='field5Text' />
        </div>
        <button type='submit' className='formSubmit'>SUBMIT</button>
    </form>
  )
}

export default Form;