import React from "react";
import Footer from "../components/Home/Footer";
import FooterDesktop from '../components/Home/FooterDesktop';
import TestimonialsDesktop from '../components/Home/TestimonialsDesktop';
import MediaQuery from "react-responsive";
import ClientLogos from "../components/Home/ClientLogos";
import Testimonials from "../components/Home/Testimonials";
import HypeComp from "../components/HypeComp";
import IndustriesCater from "../components/IndustriesCater";
import Navbar from "../components/Navbar";
import OptUs from "../components/OptUs";
import Requirements from "../components/Requirements";
import img1 from "../media//image 17.png";
import bgImg from "../media/image 16.png";

import img61 from "../media/image 20.png";
import img62 from "../media/image 21.png";
import img65 from "../media/image 22.png";
import img66 from "../media/image 23.png";

function ITHiring() {
  return (
    <div className=" font-poppins">
      <div className='fixed w-[100vw] z-[200] bg-white '>
          <Navbar active="recr"/>
        </div>
      <div className=" bg-gradient-to-br from-[#84C441]/30 via-[#74CA86]/30 to-[#65D0CA]/30 h-[40vh] lg:clip-style-2 lg:w-[30vw]  clip-style -z-10"></div>
      <div className="z-10 flex flex-col items-center pt-2 mx-5 -mt-80">
        <div className=" bg-[#EBFFD6] p-6 rounded-full mt-14 z-10 lg:mt-24">
          <img src={img1} alt="" className="z-10" />
        </div>
        <h1 className="mt-3 font-bold text-xl text-[#23496A] z-10 ">
          Tech Hiring Services
        </h1>
        <img
          src={bgImg}
          alt=""
          className="z-10 mx-10 mt-[2vw] shadow-md shadow-gray-600"
        />
        <h1 className="mt-4 text-sm lg:text-center lg:text-[0.9vw] lg:leading-[24px] lg:mt-7 lg:text-base lg:mx-[30vw]">
          Undermining the importance of hiring skilled IT personnel is
          unthinkable for any company. However, finding suitable and trustworthy
          employees with the necessary IT skills can be difficult. Jobs
          Territory recognizes the significance of IT staffing and provides
          comprehensive and flexible IT outsourcing services.
        </h1>
      </div>
      <div className="pb-2 ">
        <Requirements />
      </div>
      <div className="py-4 ">
        <OptUs text="Workforce Solutions, Managed Services, and Project Solutions are the three categories of our Tech recruitment services. Each covers a wide range of IT Staffing services and solutions. We provide Workforce Solutions services and ensure they are tailored to your needs, such as cost, risk, time, control, and expertise." />
      </div>
      <div className="flex flex-col items-center px-4 py-0">
        <h1 className=" text-[#84C441] text-2xl text-center font-bold tracking-wide ">
          <div className="flex flex-col items-center px-4 py-0">
            <h1 className=" text-[#84C441] text-2xl text-center font-bold tracking-wide ">
              What’s the hype behind Jobs Territory’s Tech Hiring Service?
            </h1>
          </div>
        </h1>
        <div className="grid grid-cols-2 mt-8 lg:grid-cols-4 gap-x-10 lg:gap-x-36 gap-y-7">
          <HypeComp
            img={img61}
            text="No more hunting around
            "
          />
          <HypeComp
            img={img62}
            text="No hush of payments. Only pay if you make a hire.
"
          />
          <HypeComp
            img={img65}
            text="Nominal budget with lowered fill time
"
          />
          <HypeComp
            img={img66}
            text="Get the privilege to choose the best personnel for your company.
            "
          />
        </div>
      </div>
      <IndustriesCater />
      <div className="mt-10">
      <MediaQuery query="(max-width: 480px)">
        <div className="bg-gradient-to-l from-[#84C441] via-[#74CA86] to-[#65D0CA] w-[100vw] flex space-y-5 flex-col items-center py-2">
          <h1 className="text-white text-2xl font-bold">OUR CLIENTS</h1>
        </div>
        <div className="aliceSm w-[100vw] flex flex-col items-center">
          <div className="w-[90vw] mt-3">
            <ClientLogos dir='ltr' mid='true'/>
          </div>
          <div className="w-[90vw] mt-2">
            <ClientLogos dir='rtl' mid='false'/>
          </div>
        </div>
        <div className="mt-3">
            <Testimonials />
        </div>
        <div className="mt-10">
            <Footer/>
        </div>
      </MediaQuery>
      <MediaQuery query="(min-width: 540px)">
        <div className="aboutClients w-[100vw] flex space-y-5 flex-col items-center py-2">
          <h1 className="text-white text-[36px] font-bold ">OUR CLIENTS</h1>
        </div>
        <div className="aliceLg flex items-center w-[100vw] ml-[2vw]">
          <ClientLogos dir='ltr' />
        </div>
        <div className="mt-[14vh]">
          <TestimonialsDesktop />
        </div>
        <div className="mt-[8vh]">
          <FooterDesktop />
        </div>
      </MediaQuery>
      </div>
    </div>
  );
}

export default ITHiring;
