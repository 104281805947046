import React from "react";
import {MobileOnlyView,BrowserView} from "react-device-detect";

function Benefits({ num, title, content }) {
  return (
    <div className="flex items-start justify-between pb-2 mx-6 space-x-3 lg:w-[50vw]">
      <div className="">
        <h1 className="bg-gradient-to-tr from-[#EE0E5F] to-[#E22FFF] px-4 py-2 lg:p-2 rounded-full text-white">
          {num}
        </h1>
      </div>
      <div className="flex flex-col ">
        <MobileOnlyView>
        <h1 className="text-sm font-semibold">{title}</h1>
        <h1 className="text-xs lg:mb-10 ">{content}</h1>
        </MobileOnlyView>
        <BrowserView>
        <h1 className="text-lg font-semibold">{title}</h1>
        <h1 className="text-md lg:mb-10 ">{content}</h1>
        </BrowserView>
      </div>
    </div>
  );
}

export default Benefits;
