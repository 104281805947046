import React from 'react';
import jt from '../../assets/jt-logo.png';
import fb from '../../assets/fb.png';
import insta from '../../assets/insta.png';
import link from '../../assets/link.png';

function Footer() {
  return (
    <div className='search-footer'>
        <img src={jt} className='search-footerLogo' />
        <button className='dropButton'>HOME</button>
        <span className='line'/>
        <button className='dropIcon'>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.78008" cy="7.78008" r="7.78008" fill="#84C441"/>
                <path d="M1.86725 5.60168L7.78011 11.5145L13.693 5.60168" stroke="black" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </button>
        <button className='dropButton' style={{left: '93px', top: '204px'}}>
        RECRUITMENT SERVICES</button>
        <span className='line' style={{top: '237px'}} />
        <button className='dropIcon' style={{top: '224px'}}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.78008" cy="7.78008" r="7.78008" fill="#84C441"/>
                <path d="M1.86725 5.60168L7.78011 11.5145L13.693 5.60168" stroke="black" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </button>
        <button className='dropButton' style={{left: '107px', top: '278px'}}>
        WOMEN'S TERRITORY</button>
        <span className='line' style={{top: '310px'}} />
        <button className='dropIcon' style={{top: '297px'}}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.78008" cy="7.78008" r="7.78008" fill="#84C441"/>
                <path d="M1.86725 5.60168L7.78011 11.5145L13.693 5.60168" stroke="black" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </button>
        <button className='dropButton' style={{left: '149px', top: '352px'}}>
        KNOW MORE</button>
        <span className='line' style={{top: '382px'}} />
        <button className='dropIcon' style={{top: '372px'}}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.78008" cy="7.78008" r="7.78008" fill="#84C441"/>
                <path d="M1.86725 5.60168L7.78011 11.5145L13.693 5.60168" stroke="black" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </button>
        <p className='newsletterTitle'>
            Subscribe to our newsletter
        </p>
        <div className='emailInput'>
            <input type='email' className='emailText' placeholder='Mail'/>
        </div>
        <button type='submit' style={{top: '604px', left: '139px'}} 
            className='formSubmit'>SUBMIT</button>
        <div className='companyInfo'>
            <h1 className='companyInfoTitle'>Jobs Territory</h1>
            <span className='companyInfoText'>
                <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg" className='locationIcon'>
                    <path d="M11 0C8.08368 0.00344047 5.28779 1.16347 3.22563 3.22562C1.16348 5.28778 0.00345217 8.08367 1.17029e-05 11C-0.00348119 13.3832 0.774991 15.7018 2.21601 17.6C2.21601 17.6 2.51601 17.995 2.56501 18.052L11 28L19.439 18.047C19.483 17.994 19.784 17.6 19.784 17.6L19.785 17.597C21.2253 15.6996 22.0034 13.3821 22 11C21.9965 8.08367 20.8365 5.28778 18.7744 3.22562C16.7122 1.16347 13.9163 0.00344047 11 0ZM11 15C10.2089 15 9.43552 14.7654 8.77772 14.3259C8.11993 13.8863 7.60724 13.2616 7.30449 12.5307C7.00174 11.7998 6.92252 10.9956 7.07686 10.2196C7.2312 9.44371 7.61217 8.73097 8.17158 8.17156C8.73099 7.61215 9.44372 7.23119 10.2196 7.07685C10.9956 6.92251 11.7998 7.00172 12.5307 7.30447C13.2616 7.60722 13.8863 8.11992 14.3259 8.77771C14.7654 9.43551 15 10.2089 15 11C14.9987 12.0604 14.5768 13.0771 13.827 13.827C13.0771 14.5768 12.0605 14.9987 11 15Z" fill="#F8F8F8"/>
                </svg>
                1st floor, 110, Cambridge Rd,Ulsoor, Bengaluru,<br/> Karnataka 560008.
                <br/><br />
                <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='mailIcon'>
                    <path d="M17 3.63246V11.3077C17 12.0217 16.7201 12.7065 16.222 13.2114C15.7239 13.7163 15.0482 14 14.3438 14H2.65625C1.95177 14 1.27614 13.7163 0.777998 13.2114C0.279854 12.7065 0 12.0217 0 11.3077V3.63246L8.23013 8.54108C8.31189 8.58995 8.40508 8.61573 8.5 8.61573C8.59492 8.61573 8.68811 8.58995 8.76987 8.54108L17 3.63246ZM14.3438 3.33337e-08C14.9973 -0.000104156 15.628 0.244038 16.1152 0.68572C16.6023 1.1274 16.9116 1.73561 16.9841 2.394L8.5 7.45231L0.0159376 2.394C0.0883557 1.73561 0.397719 1.1274 0.884845 0.68572C1.37197 0.244038 2.00266 -0.000104156 2.65625 3.33337e-08H14.3438Z" fill="#F8F8F8"/>
                </svg>
                careers@jobsterritory.com<br/>
                <br/>
                <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='contactIcon'>
                    <path d="M2.53204 0.482696L3.68492 0.104292C4.76601 -0.249912 5.92103 0.323195 6.3839 1.44301L7.30535 3.67383C7.70607 4.64515 7.48321 5.78806 6.75462 6.49867L4.72423 8.47649C4.84959 9.6601 5.23745 10.825 5.88675 11.9712C6.50333 13.0802 7.33039 14.0509 8.31894 14.8258L10.7576 13.9898C11.6811 13.6741 12.6883 14.0382 13.254 14.8929L14.5751 16.8839C15.2341 17.8783 15.1151 19.2489 14.2976 20.0915L13.4222 20.9946C12.5501 21.8933 11.304 22.2201 10.1522 21.8504C7.43071 20.9792 4.93102 18.3931 2.64883 14.0921C0.363431 9.78441 -0.44337 6.12796 0.2295 3.12713C0.512362 1.86431 1.38774 0.857801 2.53204 0.482696Z" fill="#F8F8F8"/>
                </svg>
                +91 9686116232
            </span>
        </div>
        <p className='followText'>
            Follow us on
        </p>
        <button className='socialIcon'>
            <img src={insta} />
        </button>
        <button className='socialIcon' style={{left: '156px'}}>
            <img src={fb} />
        </button>
        <button className='socialIcon' style={{left: '221px'}}>
            <img src={link} />
        </button>
        <p className='copyrightText'>
            © Copyright Jobs Territory 2022. All right reserved.
        </p>
    </div>
  )
}

export default Footer;