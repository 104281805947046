import React,{useState} from "react";
import { useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";
import { PopupButton } from "react-calendly";
import axios from 'axios';

function DemoModal({ modal, setModal }) {
  
  const [data, setData] = useState({name:"",phone:"",email:"",company:"",
    positions: "",profile: ""});

  const handleChange=(e) => {
    const {name,value} = e.target;
    setData(prev=> {
      return {...prev,[name]: value} });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post("https://jobs-territory-api.herokuapp.com/jobsterritory/demo", {name: data.name,
      email:data.email, contact: data.phone, company_name: data.company, 
      no_of_positions:data.positions,  position_hiring:data.profile})
    .then(
      ()=>console.log("Success"));
    setModal(false);
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none font-poppins">
        <div className="flex flex-col bg-white rounded-xl h-[93vh] p-4 w-[90vw] lg:w-[40vw] lg:h-[80vh]">
          <div className="flex items-center self-start ml-5 space-x-4">
            <BiArrowBack
              className="self-start w-6 h-6 text-black cursor-pointer"
              onClick={() => {
                setModal(false);
              }}
            />
            <h1 className="text-lg font-bold ">Requets a demo</h1>
          </div>
          <form
            className="flex flex-col mx-2 mt-5 space-y-4"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col ">
              <label className="">Your Name</label>
              <input
                className="outline-none border-[1px] border-black rounded-lg p-2 mr-5"
                name="name"
                value={data.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex flex-col ">
              <label className="">Your E-mail</label>
              <input
                className="outline-none border-[1px] border-black rounded-lg p-2 mr-5"
                name="email"
                value={data.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex flex-col ">
              <label className="">Your Contact Number</label>
              <input
                className="outline-none border-[1px] border-black rounded-lg p-2 mr-5"
                name="phone"
                value={data.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex flex-col ">
              <label className="">Company Name</label>
              <input
                className="outline-none border-[1px] border-black rounded-lg p-2 mr-5"
                name="company"
                value={data.company}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex flex-col ">
              <label className="">Number of positions</label>
              <input
                className="outline-none border-[1px] border-black rounded-lg p-2 mr-5"
                name="positions"
                value={data.positions}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex flex-col ">
              <label className="">Which position for hiring?</label>
              <input
                className="outline-none border-[1px] border-black rounded-lg p-2 mr-5"
                name="profile"
                value={data.profile}
                onChange={handleChange}
                required
              />
            </div>
            <button className="self-center  bg-gradient-to-b from-[#84C441] to-[#65D0CA] text-white py-2 px-4 mt-4 rounded-lg">
              <PopupButton
                  url="https://calendly.com/jobsterritory"
                  /*
                  * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                  * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                  */
                    rootElement={document.getElementById("root")}
                    text="          Submit            "
                    pageSettings={{
                    backgroundColor: "ffffff",
                    hideEventTypeDetails: false,
                    hideLandingPageDetails: false,
                    primaryColor: "00a2ff",
                    textColor: "4d5055",
                  }}
                  />
            </button>
          </form>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
}

export default DemoModal;
