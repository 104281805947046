import React, { useRef, useState } from 'react';
import '../components/Form.css';
import axios from 'axios';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import OTPInput from "otp-input-react";
import { authhelper } from "../firebase-config";
import wtForm from '../assets/wtFormSm.png';
import { useNavigate } from 'react-router-dom';

const Form = () => {
  const navigate = useNavigate();

  const [visibilty, setvisibility] = useState(true);
  const [data, setData] = useState({ name: "", phone: "", email: "",notifications: false});
  const [enabled, setEnabled] = useState(true);
  const [OTP, setOTP] = useState("");
  const [visible, setVisible] = useState(false);
  const [OTPVisible, setOTPVisible] = useState(false);

  const phoneRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData(prev => {
      return { ...prev, [name]: value }
    })
  };

  const handleNotifications=()=>{
    setData(prev => {
      return { ...prev, notifications: !prev.notifications }
    })
  }

  const generateRecaptchaVerifier = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => { },
      },
      authhelper
    );
  };

  const getOTP = (e) => {
    if (phoneRef.current.value.length === 10) {
      let phoneNumber = "+91" + phoneRef.current.value;
      console.log(phoneNumber);
      generateRecaptchaVerifier();
      let appVerifier = window.recaptchaVerifier;
      setVisible(true);
      setOTPVisible(true);
      signInWithPhoneNumber(authhelper, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setEnabled(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const verifyOTP = () => {
    if (OTP.length === 6) {
      window.confirmationResult
        .confirm(OTP)
        .then((result) => {
          setVisible(false);
          setEnabled(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post("https://jobs-territory-api.herokuapp.com/jobsterritory/womensterritory/feedback",{name: data.name, 
        contact: data.phone, email: data.email,notifications: data.notifications}).then(
        (res)=>{
          const id = res.data.id;
          navigate('/questions', {state: {id} });
          setData({name:"",phone:"",email:"",notifications: false});
        }).catch((err)=>console.log(err));
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='bg-[#DCC9D4] w-full flex flex-col items-center'>
          <div className='w-[95%] formBox mt-10 mb-10 flex flex-col items-center'>
            <h1 className='text-[24px] font-semibold mt-6 whitespace-nowrap self-center'>Be the next SuperWomen</h1>
            <div className='flex flex-col text-left ml-8 mt-6 w-[95vw]'>
              <h2 className='text-[16px] mb-1'>Your Name *</h2>
              <div className='w-[95%] inputBox h-[2.7rem] -ml-2'>
                <input name="name" className='outline-none w-11/12 h-5/6 border-none ml-2 mt-1 text-[14px] bg-white' value={data.name} onChange={handleChange} pattern="[a-zA-Z]+" onInvalid={e => e.target.setCustomValidity("Please enter alphabets only.")} onInput={e => e.target.setCustomValidity('')} required />
              </div>
              <h2 className='text-[16px] mb-1 mt-4'>Your Email *</h2>
              <div className='w-[95%] inputBox h-[2.7rem] -ml-2'>
                <input name="email" type='email' value={data.email} className='bg-white outline-none h-5/6 w-11/12 border-none ml-2 mt-1 text-[14px]' onChange={handleChange} required />
              </div>
              <h2 className='text-[16px] mb-1 mt-4'>Phone Number *</h2>
              <div className='w-[95%] inputBox h-[2.7rem] -ml-2'>
                <input name="phone" className='bg-white outline-none w-11/12 h-5/6 border-none ml-2 mt-1 text-[14px]' type="numeric" value={data.phone} ref={phoneRef} onChange={handleChange} required />
              </div>
            </div>
            {!OTPVisible ? (
              <button type="button" className='bg-[#E9158E] text-white text-[20px] p-0.5 w-36 mt-10 rounded-lg' onClick={getOTP} style={{ top: '373px' }}>Get OTP</button>
            ) : (
              ""
            )}
            {visible ? (
              <div className="mt-10">
                <div className="flex font-extrabold justify-center otp-input-container">
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    inputClassName="rounded-md outline-none border-[1px] border-gray-400 "
                    className=""
                  />
                </div>
                <button
                  type="button"
                  onClick={verifyOTP}
                  className=" text-center text-white p-1.5 px-4 mt-3 bg-[#e9158e] rounded-lg otp-input-button" style={{ marginLeft: "20vw", fontFamily: "Poppins" }}
                >
                  Verify OTP
                </button>
              </div>
            ) : (
              ""
            )}
            <div className='flex flex-row items-center mt-10 w-[90%]'>
              <input type='checkbox' className='-mt-3' required/>
              <h2 className='w-[100%] text-[16px] text-[#565656] ml-2'>I agree to Terms & Conditions and Privacy Policy</h2>
            </div>
            <div className='flex flex-row items-center mt-2 w-[90%]'>
              <input type='checkbox' className='' onClick={handleNotifications}/>
              <h2 className='w-[100%] text-[16px] text-[#565656] ml-2'>I wish to receive notification on whatsApp</h2>
            </div>
            <button type='submit' className='bg-[#E9158E] text-white text-[20px] p-0.5 w-36 mt-16 rounded-lg mb-10'>NEXT</button>
            <div
              id="recaptcha-container"
              className="flex items-center justify-center"
            >
            </div>
            <img src={wtForm} className='-mt-10' />
          </div>
        </div>
      </form>
    </>
  )
}

export default Form;