import React from 'react'
import bg from '../assets/bg.png';
import image from '../assets/ComingSoon.png';
import imageLg from '../assets/comingSoonLg.png';
import bgLg from '../assets/comingSoonBg.png';
import Navbar from '../components/Navbar.jsx';
import './styles.css';
import {BrowserView, MobileView} from 'react-device-detect';

function ComingSoon() {
  return (
    <>
      <MobileView>
      <div className="coming-soon-container font-poppins">
        <div className='fixed w-[100vw] z-[200] bg-white '>
          <Navbar />
        </div>
        <img src={bg} className='bg -mb-[40px]'/>
        <div className='z-20 flex flex-col items-center relative -top-[90vh] -mb-[50vh]'>
          <img src={image} alt="" className=''/>
          <h1 className="coming-soon-text -mt-10">JOB MARKET.AI
          <h3 style={{color:"#416B15"}}>The hub of your dream jobs</h3><br/>
          <span>COMING SOON</span></h1>
        </div>
      </div>
      </MobileView>
      <BrowserView>
      <div className="font-poppins overflow-hidden">
        <div className='fixed w-[100vw] z-[200] bg-white '>
          <Navbar />
        </div>
        <img src={bgLg} className='w-[100vw]'/>
        <div className='flex flex-row -center -mb-[100vh]'>
          <div className='z-20 flex flex-col relative left-[22vw] -top-[100vh] text-center'>
            <h1 className='text-white text-[48px] font-semibold whitespace-nowrap'>JOB MARKET.AI
              <h3 className='text-[#416B15] text-[36px] mt-3 mb-3 font-medium'>The hub of your dream jobs</h3>
              <span className='font-bold text-[64px]'>COMING SOON</span>
            </h1>
          </div>
          <img src={imageLg} className='relative w-[70vw] h-[75vh] ml-[22vw] -top-[115vh]'/>
        </div>
      </div>
      </BrowserView>
    </>
  )
}

export default ComingSoon