import React from "react";
import Navbar from "../components/Navbar";
import { AiOutlineRight } from "react-icons/ai";
import Footer from "../components/Footer";
import WtFooterDesktop from "../components/WtFooterDesktop";
import FooterDesktop from "../components/Home/FooterDesktop";
import TeamTestimonials from "../components/TeamTestimonials";
import TeamTestimonialsBr from "../components/TeamTestimonialsBr";
import bgImg from "../media/woman1.png";
import bgImgLg from "../media/woman1Lg.png";
import img1 from "../media/wtimg2.png";
import img2 from "../media/wtimg3.gif";
import img3 from "../media/wtimg4.gif";
import img4 from "../media/wtimg5.png";
import img5 from "../media/wtimg6.gif";
import img6 from "../media/wtimg7.png";
import Benefits from "../components/Benefits";
import Form from "./WTform";
import WtFormDesktop from "../components/WtFormDesktop";
import MediaQuery from "react-responsive";
import "./wt.css"

function WomensTerritory() {
  return (
    <>
      <div className="relative font-poppins ">
      <div className='fixed w-[100vw] z-[200] bg-white '>
            <Navbar active="wt"/>
        </div>
        <MediaQuery query="(max-width: 480px)">
          <div className="main-women-background bg-gradient-to-bl from-[#EE0E5F5C] to-[#E22FFF5C] flex flex-col items-center font-poppins clip-style-3 h-[115vh] " />
          <div className="absolute sm:flex sm:flex-col items-center top-20 lg:top-14" style={{ zIndex: 2 }}>
            <h1 className=" text-[#8D195B] text-3xl font-bold text-center lg:pt-0 pt-10 women-main-text">
              There is no limit to<br /> what we, as "WOMEN", <br /> can accomplish.{" "}
            </h1>
            {/* <div className="flex items-center px-2 mt-10 bg-white rounded-xl get-started-button-wt">
              <a href="#form" className="flex items-center py-2 pl-2 pr-1 bg-white rounded-xl " style={{ zIndex: 2, border: 'none', outline: "none", color: "black" }}>
                Get Started
              </a>
              <AiOutlineRight className="w-4 h-4 mb-1" />
            </div> */}
            <div className="women-mImage-container">
            <img src={bgImg} className="-mt-16 women-main-image z-0" />
            </div>
          </div>
          <div className="flex flex-col items-center mt-14 mx-5" style={{ zIndex: 3 }}>
            <h1 className=" text-[#D82D7E] text-2xl font-bold">WHO WE ARE</h1>
            <h1 className="py-5 text-sm">
              With the motto of "Empowered Women Empowers Women," we unravel the
              pool of talented applicants and enable women to kickstart/reignite
              their careers by offering remote recruiting jobs. We're on a mission
              to help as many "Super Women" as possible.
            </h1>
            <img src={img1} alt="" />
            <h1 className="text-sm ">
              Our vision is to become the employers' preferred Recruitment Agency
              to unshackle women- 'The Silent Warriors of our Society' and connect
              businesses to the right workforce. To be a perfect remote recruiter,
              our experts will coach you to embellish your best-recruiting skills
              from a fresher to a woman ending her sabbatical. Further, our team
              helps businesses to succeed so that communities can thrive.
            </h1>
            <h1 className=" text-[#23496A] text-4xl font-bold py-10 text-center">
              WHOM DO <br /> WE CATER TO
            </h1>
            <img src={img2} alt="" />
            <h1 className="pb-10 mx-16 text-center">
              Aspiring employed women who dream to accomplish more in life.
            </h1>
            <img src={img3} alt="" />
            <h1 className="pb-10 mx-16 text-center">
              Young females who completed their academics and looking for an
              internship or fresher jobs.{" "}
            </h1>
            <img src={img4} alt="" />
            <h1 className="pb-10 mx-16 text-center">
              Women trying to restart their careers after going on a sabbatical.{" "}
            </h1>
            <img src={img5} alt="" />
            <h1 className="pb-10 mx-16 text-center">
              Potential applicants casting around remote jobs both in full-time or
              part-time.{" "}
            </h1>
          </div>
          <div className="mt-10">
          <TeamTestimonials />
        </div>
        <div className="flex flex-col items-center mt-10">
          <div className=" absolute bg-gradient-to-b from-[#ee0e603a]/10 to-[#E22FFF1A]/10 h-[150vh] w-full clip-style-4 z-10"></div>
          <h1 className=" text-[#8D195B] py-10  font-bold">
            BENEFITS OF WORKING IN JOBS TERRITORY
          </h1>
          <Benefits
            num="1"
            title="We offer complete flexibility in terms of working hours and work location."
            content="Dedicate a minimum of 15 hours per week, which you can later increase to full-time. We work entirely remotely, so you can finish projects from wherever you want."
          />
          <Benefits
            num="2"
            title="We make sure that the new projects are diversified and engaging."
            content="You can not only collaborate with IT recruitment projects but also work for clients in other industries, such as BPO/SSC, Engineering, Marketing, and Sales. You will always have a choice."
          />
          <Benefits
            num="3"
            title="We emphasize effective interaction and coordination."
            content="As a community members, we give you lots of tools to help you with the hiring process, such as the ATS system and the Slack communication channel."
          />
          <Benefits
            num="4"
            title="We pay the entire cost of recruitment advertisements."
            content="You will only receive access to the Sourcer Team when you declare full-time cooperation with us. Moreover, you can also work with our premium Marketing Department to create eye-catching advertisements."
          />
          <Benefits
            num="5"
            title="We offer network access to professionals."
            content="Get in touch with more than 200 HR professionals– share and exchange knowledge and their experiences to learn the best of the best skills. Further, to polish up your techniques, meetings, training, and webinars are available as soon as you join the Community."
          />
          <Benefits
            num="6"
            title="We handle the entire project acquisition process."
            content="From customer service, project acquisition, and administration to contracts and invoicing, it's all our responsibility. You can fully concentrate on recruiting and project management."
          />
          <img src={img6} alt="" className="z-10 " />
          <h1 className="mx-5 text-[#8D195B] font-bold">
            Get the privilege to enjoy the best of both– full-time and freelance
            work.
          </h1>
          <h1 className="mx-5 my-5">
            Being a recruitment expert and serving prestigious clients can be
            very difficult alongside a standard job is exhausting and demanding.
            <br />
            <br /> If you are looking to work for yourself but fear from lack of
            sales skills, no worries– we are here for you! <br />
            <br />
            Jobs Territory offers its recruiters and HR professionals a
            versatile collaboration model for amping up the whole process. It’s
            all up to you. You will be free to decide how much time you want to
            devote to us, and we ensure that the time you set aside is filled
            with recruitment projects that pay well!
          </h1>
        </div>
        <div id="form" className="mt-2">
          <Form />
        </div>
        <div className="mt-10">
          <Footer />
        </div>
        </MediaQuery>
        <MediaQuery query="(min-width: 540px)">
          <div className="bg-gradient-to-bl from-[#EE0E5F5C] to-[#E22FFF5C]  h-[90vh] " />
          <div className="flex flex-row items-center absolute -top-20">
            <div className="flex flex-col items-center text-center ml-[18vw] mt-24">
              <h1 className=" text-[#8D195B] text-[40px] w-[30vw] font-extrabold text-center ">
                There is no limit to<br /> what we, as "WOMEN", <br /> can accomplish.{" "}
              </h1>
              {/* <div className="flex items-center px-2 bg-white rounded-xl mt-32">
                <a href="#form" className="flex items-center py-2 pl-2 pr-1 bg-white rounded-xl " style={{ zIndex: 2, border: 'none', outline: "none", color: "black" }}>
                  Get Started
                </a>
                <AiOutlineRight className="w-4 h-4 mb-1" />
              </div> */}
            </div>
              <img src={bgImgLg} className="w-[60vw] mt-[5rem] -ml-40" />
          </div>
          <div className="container -mt-[2vh] z-50">
            <div className="row" style={{overflow:"hidden"}}>
              <div className="col-lg-4 ml-20" style={{overflow:"hidden"}}>
                <img src={img1} className="w-[35vw]"  />
              </div>
              <div className="col-lg-6 mt-10 ml-16 mb-8">
                <h1 className=" text-[#D82D7E] text-[2.25vw] font-bold text-center">WHO WE ARE</h1>
                <h1 className="py-5 text-[1.15vw] text-center">
                  With the motto of "Empowered Women Empowers Women," we unravel the
                  pool of talented applicants and enable women to kickstart/reignite
                  their careers by offering remote recruiting jobs. We're on a mission
                  to help as many "Super Women" as possible.
                </h1> <h1 className="text-[1.15vw] text-center ">
                  Our vision is to become the employers' preferred Recruitment Agency
                  to unshackle women- 'The Silent Warriors of our Society' and connect
                  businesses to the right workforce. To be a perfect remote recruiter,
                  our experts will coach you to embellish your best-recruiting skills
                  from a fresher to a woman ending her sabbatical. Further, our team
                  helps businesses to succeed so that communities can thrive.
                </h1>
              </div>
            </div>
          </div>
          <div className="break-container bottom-4"></div>
          <div className="whom-do-we-container -mt-6">
          <h1 className=" text-[#23496A] text-4xl font-bold py-10 text-center">
              WHOM DO WE CATER TO
            </h1>
            <div className="container -mt-6">
              <div className="row">
                <div className="col-lg-3">
                  <img src={img2} alt="" className="text-center mb-6 ml-4" style={{height:"25vh"}}/>
                  <h1 className="pb-6 mx-16 text-center">
                    Aspiring employed women who dream to accomplish more in life.
                  </h1>
                </div>
                <div className="col-lg-3">
                  <img src={img3} alt="" className="text-center mb-4 ml-8" style={{height:"25vh"}}/>
                  <h1 className="pb-6 mx-16 text-center">
                    Young females who completed their academics and looking for an
                    internship or fresher jobs.{" "}
                  </h1>
                </div>
                <div className="col-lg-3">
                  <img src={img4} alt="" className="text-center mt-4 pl-24 mb-1" style={{height:"25vh"}}/>
                  <h1 className="pb-6 mx-16 text-center">
                    Women trying to restart their careers after going on a sabbatical.{" "}
                  </h1>
                </div>
                <div className="col-lg-3">
                  <img src={img5} alt="" className="text-center mt-4 pl-8 mb-1" style={{height:"25vh"}}/>
                  <h1 className="pb-6 mx-16 text-center">
                    Potential applicants casting around remote jobs both in full-time or
                    part-time.{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8">
          <TeamTestimonialsBr />
        </div>
        <div className="mt-2 flex flex-col items-center">
          <div className="relative w-full clip-style-4 z-10"></div>
          <h1 className=" text-[#8D195B] py-10  font-bold text-4xl">
            BENEFITS OF WORKING IN JOBS TERRITORY
          </h1>
          <Benefits
            num="1"
            title="We offer complete flexibility in terms of working hours and work location."
            content="Dedicate a minimum of 15 hours per week, which you can later increase to full-time. We work entirely remotely, so you can finish projects from wherever you want."
          />
          <Benefits
            num="2"
            title="We make sure that the new projects are diversified and engaging."
            content="You can not only collaborate with IT recruitment projects but also work for clients in other industries, such as BPO/SSC, Engineering, Marketing, and Sales. You will always have a choice."
          />
          <Benefits
            num="3"
            title="We emphasize effective interaction and coordination."
            content="As a community members, we give you lots of tools to help you with the hiring process, such as the ATS system and the Slack communication channel."
          />
          <Benefits
            num="4"
            title="We pay the entire cost of recruitment advertisements."
            content="You will only receive access to the Sourcer Team when you declare full-time cooperation with us. Moreover, you can also work with our premium Marketing Department to create eye-catching advertisements."
          />
          <Benefits
            num="5"
            title="We offer network access to professionals."
            content="Get in touch with more than 200 HR professionals– share and exchange knowledge and their experiences to learn the best of the best skills. Further, to polish up your techniques, meetings, training, and webinars are available as soon as you join the Community."
          />
          <Benefits
            num="6"
            title="We handle the entire project acquisition process."
            content="From customer service, project acquisition, and administration to contracts and invoicing, it's all our responsibility. You can fully concentrate on recruiting and project management."
          />
          <img src={img6} className="z-10 -mt-14" />
          <h1 className="mx-5 text-[#8D195B] text-center font-bold lg:text-4xl lg:w-[50vw]">
            Get the privilege to enjoy the best of both– full-time and freelance
            work.
          </h1>
          <h1 className="mx-5 my-5 text-[1vw] w-[70vw] text-center">
            Being a recruitment expert and serving prestigious clients can be
            very difficult alongside a standard job is exhausting and demanding.
            <br />
            <br /> If you are looking to work for yourself but fear from lack of
            sales skills, no worries– we are here for you! <br />
            <br />
            Jobs Territory offers its recruiters and HR professionals a
            versatile collaboration model for amping up the whole process. It's
            all up to you. You will be free to decide how much time you want to
            devote to us, and we ensure that the time you set aside is filled
            with recruitment projects that pay well!
          </h1>
        </div>
        <div className="mt-2" id="form">
          <WtFormDesktop />
        </div>
        <div className="mt-14">
          <FooterDesktop />
        </div>
        </MediaQuery>
      </div>
    </>
  );
}

export default WomensTerritory;
