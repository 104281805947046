import React from "react";

function HypeComp({ img, text }) {
  return (
    <div className="flex flex-col items-center space-y-2">
      <div className=" bg-[#EFFFDE] rounded-full p-4">
        <img src={img} alt="" className="" />
      </div>
      <h1 className="text-sm text-center lg:max-w-[8vw] ">{text}</h1>
    </div>
  );
}

export default HypeComp;
