import React,{ useState,useEffect } from 'react';
import AliceCarousel from "react-alice-carousel";
import jt from "../media/logo.png";
import TestimonialModal from '../components/Home/TestimonialModal';
import "react-alice-carousel/lib/alice-carousel.css";
import axios from "axios";

const TestimonialsDesktop = () => {
    
    const [cards, setCards] = useState([]);
    const [read,setRead] = useState(false);
    const [testimonialData,setTestimonialData] = useState(undefined);

    useEffect(() => {
      getCards();
    }, []);
  
    const handleDragStart = (e) => e.preventDefault();
  
    const getCards = async () => {
      const getdata = await axios.get(
      "https://jobsterritorybackend.herokuapp.com/teamTestimonials"
    );
      // let url = "https://jobsterritorybackend.herokuapp.com/teamTestimonials";
      // let data = await fetch(url);
      // let parsedData = await data.json();
        const parsedData = [...getdata.data];
        parsedData.sort((test1, test2)=>{
          return ((test1.priority != undefined) ? +test1.priority : 2) - ((test2.priority != undefined) ? +test2.priority : 2);
        });
      setCards(parsedData);
    };

    const responsive = {
        1036: {items: 3}
      };

    const clientTest = cards.map((card,index) => {
        return (
          <>
            {read?<TestimonialModal client={false} item={testimonialData} setRead={setRead} show={read}/>:  
        <div key={index} className='testCard ml-8' onDragStart={handleDragStart} role="presentation">
          <div className='testCardContent'>
              <p className='testCardReview'>{card.review.length>120?(
                      card.review.slice(0,120)+"..."
                        ) :(
                          card.review
                        )}</p>
              <p className='testCardCredentials'><span style={{fontWeight: '700'}}>{card.name}
              </span><br/>{card.designation.length>30?(card.designation.match(/(\b\w)?/g).join("").toUpperCase()):card.designation}, {card.company}</p>
              <img src={card.imageUrl} alt={card.name} className="testCardImg" />
              <svg width="116" height="60" viewBox="0 0 136 67" fill="none" xmlns="http://www.w3.org/2000/svg" className='logoBg'>
                  <circle cx="81.5" cy="81.5" r="81.5" fill="white"/>
              </svg>
              <img src={jt} className='testCardLogo -ml-1 mt-1' />
              {(card.review.length>120 || card.designation.length>30 )&&<button style={{top: '370px', fontSize: '18px'}} className='cardReadMore' onClick={()=>{setTestimonialData(card);setRead(true)}}
                >READ MORE...</button>}
          </div>
          
        </div>
            }
          </>
        );
      });
  return (
    <div className='teamTestimonials w-[100vw] h-[560px] flex flex-col items-center text-center z-30'>
      <h1 className='text-white mt-3 mb-1 text-[1.75vw] font-bold leading-[40px]'>What JTians have<br/>to say</h1>
      <div className='w-[85vw] h-[50vh] mt-[-20px] ml-[5%] overflow-visible overflow-x-hidden'>
        <AliceCarousel
        mouseTracking
        responsive={responsive}
        items={clientTest}
        autoPlay={true}
        autoPlayInterval={2000}
        infinite={true}
        disableDotsControls={true}
        renderPrevButton={()=> {
          return <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle r="29" transform="matrix(-1 0 0 1 29 29)" fill="#D9D9D9" fillOpacity="0.5"/>
            <path d="M34.4133 12.3733L15.08 27.0666L34.4133 44.08" stroke="black" strokeOpacity="0.5" strokeWidth="3" strokeLinecap="round"/>
            </svg>}}
          renderNextButton={()=>{
            return <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="29" cy="29" r="29" fill="#D9D9D9" fillOpacity="0.5"/>
              <path d="M23.5867 12.3733L42.92 27.0666L23.5867 44.08" stroke="black" strokeOpacity="0.5" strokeWidth="3" strokeLinecap="round"/>
              </svg>}}
        />
      </div>
    </div>
  )
}

export default TestimonialsDesktop;