import React from 'react';
import Modal from 'react-bootstrap/Modal';
import "./modal.css"
import jt from "../../media/logo.png";


const TestimonialModal = ({ setRead, item, show, client }) => {


    return (
        <Modal show={show}>
                <div className="">
                        <div className="readMoreBlock"  style={{marginBottom:"10vh"}}>
                    <button className='closeReadMore' onClick={() => setRead(false)}>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L24.5 24.5M24.5 1L1 24.5" stroke="#000000" strokeWidth="2" />
                        </svg>
                    </button>
                            <p className='reviewRm' >{item.review}</p>
                            <p className='designationRm'><span style={{ fontWeight: '700' }}>{item.name}
                            </span><br />{item.designation}, {item.company}</p>
                            <img src={item.imageUrl} className='clientImageRm' />
                            <div className="logoBgRm">
                                <svg width="137" height="68" viewBox="0 0 137 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="81.6719" cy="82.4688" r="81.5" fill="white" />
                                </svg>
                            </div>
                            {client&&<img src={item.clientLogo} className='companyLogoRm' />}
                           {!client&&<img src={jt} className='companyLogoRm'/>}
                        </div>
                </div>
        </Modal>
    )
}

export default TestimonialModal