import React,{useState} from 'react';
import logo from '../../assets/logo.png';
import NavMenu from './NavMenu';


function Navbar() {
    const [showMenu,setMenu] = useState(false);
    return (
        <div className=''>
            {/* <div className="container">
                <div className="row">
                    <div className="col-md-auto">
                        <img src={logo} className='navLogo' />
                    </div>
                    <div className="col-md-auto">
                        <button className='signUp'>
                            SIGN UP NOW
                        </button>

                    </div>
                    <div className="col-md-auto" >
                        <svg style={{right:'7vw',top:'2vh',position:'absolute'}} width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0H25V3.16667H0V0ZM0 7.91667H25V11.0833H0V7.91667ZM0 15.8333H25V19H0V15.8333Z" fill="#9E9D9D" />
                        </svg>
                    </div>
                </div>
            </div> */}
            {/* <nav class="navbar navbar-expand-lg bg-light">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#"><img src={logo} className='navLogo' /></a>
                    <a class="navbar-brand" href="#"><button className='signUp'>
                        SIGN UP NOW
                    </button></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="true" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNavAltMarkup" style={{ position: 'relative', zIndex: 100 }}>
                        <div class="navbar-nav">
                            <button className="btn">
                            <svg style={{ position: 'absolute', right: '5vw', top: '2vh' }} data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="true" aria-label="Toggle navigation" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L24.5 24.5M24.5 1L1 24.5" stroke="#C7C6C6" stroke-width="2" />
                            </svg>
                            </button>
                            <img src={logo} alt="" style={{ width: '20vw', position: 'relative', left: '40vw', top: '5vh' }} />
                            <a class="nav-link" aria-current="page" href="#">Home</a>
                            <a class="nav-link" href="#">Features</a>
                            <a class="nav-link" href="#">Pricing</a>
                            <a class="nav-link" href="#">asdfasdf</a>
                            <a class="nav-link" href="#" ><button className='signUp' style={{ position: 'relative', left: '0' }}>
                                SIGN UP NOW
                            </button></a>
                        </div>
                    </div>
                </div>
            </nav> */}
           
    <>
    <div className='navbar' style={{marginBottom:'3vh',position:'relative'}}>
        <img src={logo} className='search-navLogo' />
        <button className='search-signUp'>
            SIGN UP NOW
        </button>
        <button className='search-navDropdownIcon' onClick={()=>setMenu(true)}>
          <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0H25V3.16667H0V0ZM0 7.91667H25V11.0833H0V7.91667ZM0 15.8333H25V19H0V15.8333Z" fill="#9E9D9D"/>
          </svg>
        </button>
    </div>
    {showMenu?<NavMenu setMenu={setMenu}/> :null}
    </>
        </div>
    )
}

export default Navbar;