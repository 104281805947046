import React,{useState,useEffect} from 'react';
import {Carousel} from 'react-bootstrap';
import ReadMore from './ReadMore';
import axios from 'axios';
import TestimonialModal from './TestimonialModal';

const Testimonials=()=>{
  const [read,setRead] = useState(false);
  const [data, setdata] = useState([]);
  const [testimonialData,setTestimonialData] = useState(undefined);
  const testData = async () => {
    const getdata = await axios.get(
      "https://jobsterritorybackend.herokuapp.com/clientTestimonials"
    );
    setdata(getdata.data);
  };
  useEffect(() => {
    testData();
  }, []);

  return (
    <div className='w-[100vw] h-[615px] flex flex-col items-center carouselBg text-center'>
        <h1 className='font-bold text-[26px] text-white mt-2'>
            They trusted, we<br/> delivered.
        </h1>
        <h2 className='text-[22px] text-white mt-2 mb-2'>Testimonials</h2>
        {read?<TestimonialModal client={true} item={testimonialData} setRead={setRead} show={read} />:
        <Carousel pause={read?"true":"false"} >
        {data.map((item) => {
          return (
          <Carousel.Item key={item._id} >
            <div className='centerBg text-left'>
              <p className='review'>{item.review.length>100?(
                    item.review.slice(0,100)+"..."
                      ) :(
                        item.review
                      )}</p>
              <p className='designation'><span style={{fontWeight: '700'}}>{item.name}
              </span><br/>{item.designation.length>10?(item.designation.match(/(\b\w)?/g).join("").toUpperCase()):item.designation}, {item.company}</p>
              <img src={item.imageUrl} className='clientImage'/>
                <svg width="137" height="68" viewBox="0 0 137 68" fill="none" xmlns="http://www.w3.org/2000/svg" className='logoBg'>
                  <circle cx="81.6719" cy="82.4688" r="81.5" fill="white" />
                </svg>
                <img src={item.clientLogo} className='companyLogo' />
              {(item.review.length>100 || item.designation.length>10)&&<button className='readMore' onClick={()=>{setTestimonialData(item);setRead(true)}}
              >READ MORE...</button>}
            </div>
            
          </Carousel.Item>
        );
        })}
      </Carousel>
        } 
    </div>
  )
}

export default Testimonials;