import React, { useState } from 'react';
import Navbar from './Navbar';
import newServices from '../../assets/newServices.png';
import people from '../../assets/people.png';
import peopleNew from '../../assets/peopleNew.png';
import company from '../../assets/company.png';
import text from '../../assets/main-text.png';
import challenge from '../../assets/challengeImg.png';
import software from '../../assets/softwareImg.png';
import scout from '../../assets/scoutImg.png';
import demand from '../../assets/demandImg.png';
import pow1 from '../../assets/power1.png';
import pow2 from '../../assets/power2.png';
import pow3 from '../../assets/power3.png';
import pow4 from '../../assets/power4.png';
import textLg from '../../assets/textLg.png';
import candidate from '../../assets/candidate.png';
import newBg from '../../assets/newBg.png';
import can1s from '../../assets/can1Sm.png';
import can2s from '../../assets/can2Sm.png';
import can3s from '../../assets/can3Sm.png';
import can1 from '../../assets/can1gif.gif';
import can2 from '../../assets/can2gif.gif';
import can3 from '../../assets/can3gif.gif';
import whatsapp from '../../assets/whatsapp.png';
import img1 from '../../assets/image1gif.gif';
import img2 from '../../assets/image2gif.gif';
import img3 from '../../assets/image3gif.gif';
import companyNew from '../../assets/companyNew.png';
import Footer from './Footer';
import FooterDesktop from './FooterDesktop';
import Testimonials from './Testimonials';
import TestimonialsDesktop from './TestimonialsDesktop';
import ClientLogos from './ClientLogos';
import { useNavigate } from 'react-router-dom';
import MediaQuery from "react-responsive";

const Home=()=>{

    const navigate = useNavigate();

    const [current, setCurrent] = useState(true);

    const activeStyle={
        background: "#84C441",
        color: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
    }

    const switchRole = ()=>{
        setCurrent(prev=>!prev);
    } 

  return (
    <>
    <MediaQuery query="(max-width: 480px)">
        <div className='font-poppins overflow-hidden'>
            <div className='fixed w-[100vw] z-[100] bg-white top-0'>
                <Navbar />
            </div>
            <a className='fixed right-5 bottom-20 z-30' target='_blank'
                href='https://api.whatsapp.com/send/?phone=919686116232&text&type=phone_number&app_absent=0'>
                    <img src={whatsapp} />
                </a>
            <div className='bg w-[100vw] h-[60rem] mt-24'/>
            <div className='flex flex-col items-center'>
                <div className='flex flex-col items-center relative -top-[59rem] -mb-[59rem]'>
                    <div className='border-[1px] border-[#C7C6C6] border-solid z-10 bg-white rounded-[7px] w-[90vw] h-[5vh] flex flex-row' onClick={()=>!current?navigate('/comingSoon'):navigate('/services')}>
                        <input className='mt-1 ml-2 text-[16px] h-5/6 w-11/12 outline-none border-none' placeholder={current?"Search For Talent":"Search for Jobs"}/>
                        <button className='ml-1 mr-2'>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.6226 16.7676L25.6163 23.7613C25.8621 24.0073 26.0001 24.3408 26 24.6886C25.9999 25.0363 25.8616 25.3698 25.6156 25.6156C25.3696 25.8614 25.0361 25.9995 24.6883 25.9994C24.3405 25.9992 24.0071 25.861 23.7613 25.615L16.7676 18.6213C14.6769 20.2406 12.0479 21.0026 9.41529 20.7524C6.78272 20.5021 4.34437 19.2583 2.59627 17.2741C0.84818 15.2898 -0.0783409 12.7141 0.00519498 10.071C0.0887309 7.42789 1.17605 4.91586 3.04595 3.04595C4.91586 1.17605 7.42788 0.0887309 10.071 0.00519498C12.7141 -0.0783409 15.2898 0.84818 17.2741 2.59627C19.2583 4.34437 20.5021 6.78272 20.7524 9.41529C21.0026 12.0479 20.2406 14.6769 18.6213 16.7676H18.6226ZM10.4005 18.1988C12.4691 18.1988 14.4529 17.3771 15.9157 15.9144C17.3784 14.4516 18.2001 12.4678 18.2001 10.3992C18.2001 8.33059 17.3784 6.34672 15.9157 4.88401C14.4529 3.42129 12.4691 2.59955 10.4005 2.59955C8.33189 2.59955 6.34802 3.42129 4.88531 4.88401C3.42259 6.34672 2.60085 8.33059 2.60085 10.3992C2.60085 12.4678 3.42259 14.4516 4.88531 15.9144C6.34802 17.3771 8.33189 18.1988 10.4005 18.1988Z" fill="#C7C6C6"/>
                            </svg>
                        </button>
                    </div>
                    {!current?<><img src={people} className='w-[100vw] mt-10 h-[35vh] ' />
                    <h1 className='font-[800] text-[40px] leading-[42px] h-[90px] mt-10 mainText text-center'>Bridging Between<br/> Seekers & Seeking</h1>
                    </>:<div className='z-10 flex flex-col items-center mt-10'>
                    <img src={candidate} className='-mb-14 -mr-10 z-20 w-[49vw] h-[13vh] ' />
                    <img src={company} className=' w-[100vw] h-[40vh] -my-20'/>
                    <h1 className='font-[800] text-[40px] leading-[42px] mt-20 mainText text-center'>Bridging Between<br/> Seekers & Seeking</h1>
                    </div>}
                    <div className='z-10 bg-white rounded-[10px] w-[95vw] h-[7vh] flex flex-row mt-10 items-center align-middle'>
                        <button className='w-[50%] h-[65%] recruiterSwitch ml-2' style={current?activeStyle:null} onClick={switchRole}>
                            EMPLOYER
                        </button>
                        <button className='w-[50%] h-[65%] candidateSwitch mr-2' style={!current?activeStyle:null} onClick={switchRole}>
                            CANDIDATE
                        </button>
                    </div>
                    <div className='bg-white z-10 w-[100vw] mt-10 text-center'>
                        <div className='mt-2 bg-[#F4FAFF] h-[45px] w-[100vw] flex flex-col align-middle text-center content-center'>
                        {current?
                        <h1 className='mt-3 text-[14px] align-middle font-semibold'>Trusted by 100+ Employers</h1>
                        :
                        <h1 className='mt-3 text-[14px] align-middle font-semibold'><span className='pt-10'>Trusted by 100+ Brands that hired our candidates</span> </h1>}
                        </div>
                        <div className='aliceSm w-[100vw] flex flex-col items-center'>
                            <div className='w-[90vw] mt-3 '>
                                <ClientLogos dir='ltr' mid="true"/>
                            </div>
                            <div className='w-[90vw] mt-0'>
                                <ClientLogos dir='rtl' mid="false"/>
                            </div>
                        </div>
                    </div>
                <div className='signUpBg w-[100vw] flex flex-col items-center'>
                    {current?<>
                    <strong className='mt-10 w-[80vw] text-white font-semibold text-[20px] text-center'>
                        Take charge of your talent pool.Get Ready-to-Hire Talent with Jobs Territory.
                    </strong>
                    <button className='mt-4 w-[133px] h-[36px] text-center bg-white rounded-xl mb-10' onClick={()=>navigate('/services')}>
                        <span className='animation text-[16px] '>SIGN UP NOW
                        </span>
                    </button>
                    </>:<>
                    <strong className='mt-10 w-[80vw] text-white font-semibold text-[20px] text-center'>
                        Are You Ready For Your Dream Job? Let's Get Started.
                    </strong>
                    <button className='mt-4 w-[133px] h-[36px] text-center bg-white rounded-xl mb-10' onClick={()=>navigate('/cominsoon')}>
                        <span className='animation text-[16px] '>SIGN UP NOW
                        </span>
                    </button>
                    </>}
                </div>
                <div className='mt-10 flex flex-col items-center text-center'>
                    {!current?<>
                        <h1 className='text-[20px] leading-[30px] text-[#23496A]'>
                        Upgrade Your Career With Right<br/>Skills and Profiles<br/> 
                        <span className='text-[24px] font-semibold'>
                            Jobs That Fit You The Best
                        </span>
                    </h1>
                    <img src={can1} className='mt-1' />
                    <h1 className='text-[#23496A] text-[25px] font-medium mt-0'>Transparency Throughout<br/> the Process</h1>
                    <img src={can2} className=' h-[346px] mt-4' />
                    <h1 className='text-[#23496A]  text-[25px] font-medium mt-1'>Job Proposals That Suits<br/> Best To Your Profile</h1>
                    <img src={can3} className=' h-[346px] mt-0' />
                    <h1 className='text-[#23496A]  text-[25px] font-medium mt-0'>In-depth Feedback For<br/> Your Optimal Performance</h1>
                    </>:<>
                    <h1 className='text-[20px] leading-[30px] text-[#23496A]'>
                        Make the right hires, faster with the <br/>
                        <span className='text-[24px] font-semibold'>
                            world's leading recruiting<br/> 
                        software</span>
                    </h1>
                    <img src={img1} className='mt-2' />
                    <h1 className='text-[#23496A] text-[20px] font-bold'>MACHINE LEARNING (AI)</h1>
                    <h2 className='text-[16px] mt-2'>
                        <center className='font-semibold'>Find and attract<br/>
                        candidates</center><br/>
                        Our AI-powered sourcing finds the best<br/> prospective candidates as per the skill<br/> requirement. .
                    </h2>
                    <img src={img2} className='w-full' />
                    <h1 className='text-[#23496A] text-[20px] font-bold'>MOTIVATE & ANALYSE</h1>
                    <h2 className='text-[16px] mt-2'>
                        <center className='font-semibold'>Putting the potential personnel<br/>
                        forward</center><br />
                        From collaborating with hiring teams to<br/> evaluate applicants to collecting
                        <br/>feedback and deciding who is best.
                    </h2>
                    <img src={img3} className='w-full' />
                    <h1 className='text-[#23496A] text-[20px] font-bold'>RECRUIT & OPTIMISE</h1>
                    <h2 className='text-[16px] mt-2'>
                        <center className='font-semibold'>Effective Hiring decision in<br/>
                        half the time</center><br/> 
                        Simplifies the process and manual tasks<br/> such as scheduling, 
                        interviews and<br/> approval. 
                    </h2>
                    </>}
                </div>
                <div className='w-[100vw] bg-[#D3DBE1] flex flex-col items-center text-center mt-14'>
                    {current?<>
                    <h1 className='text-[24px] font-bold mt-2'>
                        The Jobs Territory Network
                    </h1>
                    <h3 className='text-[13px] font-semibold mb-2'>
                        We are because they are. 
                        <button className='ml-1 font-bold mt-1 text-[#84C441]'> 
                        EXPLORE ABOUT US
                        </button>
                    </h3>
                    </>:<>
                    <h1 className='text-[24px] font-bold mt-2'>
                        The Jobs Territory Network
                    </h1>
                    <h3 className='text-[13px] font-semibold mb-2'>
                        Our Networks you can benefit with. 
                        <button className='ml-1 font-bold mt-1 text-[#84C441]'> 
                        EXPLORE NOW
                        </button>
                    </h3>
                    </>
                    }
                </div>
                <div className='bg-white aliceSm w-[90vw] mt-4'>
                    <ClientLogos dir='ltr' />
                </div>
                <div className='sep1 w-[100vw] h-[34px] mt-2' />
                <div className='bg-white mt-2 flex flex-col items-center text-center'>
                    {current?<>
                        <h1 className='font-bold text-[25px] leading-[37.5px] mt-10'>Why Recruiting Has Become<br/>Challenging?</h1>
                        <img src={challenge} className='w-[75vw] h-[290px]' />
                        <div className='bg-[#E4FFC8] w-[90vw] h-[80px] text-left mb-3'>
                            <h1 className='leading-[24px] w-[95%] ml-6 mt-3 text-[15px] font-medium  flex flex-row items-center'>Urgency! Shortage of Time Leads to a Shortage of Talented Hires.</h1>
                        </div>
                        <div className='bg-[#E4FFC8] w-[90vw] h-[80px] text-left mb-3'>
                            <h1 className='leading-[24px] w-[95%] ml-6 mt-4 text-[15px] font-medium  flex flex-row items-center'>Unorganized and Subpar Applicant Info</h1>
                        </div>
                        <div className='bg-[#E4FFC8] w-[90vw] h-[80px] text-left mb-3'>
                            <h1 className='leading-[24px] w-[95%] ml-6 mt-4 text-[15px] font-medium items-center'>Massive Piles of Untapped Resumes.</h1>
                        </div>
                        <div className='bg-[#E4FFC8] w-[90vw] h-[80px] text-left mb-3'>
                            <h1 className='leading-[24px] w-[95%] ml-6 mt-4 text-[15px] font-medium items-center'>Long Exhausting Screening</h1>
                        </div>
                        </>:<>
                        <h1 className='font-bold text-[25px] leading-[37.5px] mt-10'>Often Find Yourself<br/> Wondering While<br/> Scouting Your Dream Job</h1>
                        <img src={scout} className='' />
                        <div className='bg-[#E4FFC8] w-[90vw] h-[80px] text-left mb-3'>
                            <h1 className='ml-4 w-[95%] mt-4 text-[15px] font-medium flex flex-row items-center'>How Can I Be My Best Professional Self
                                <span className='text-[32px] ml-2 font-semibold'>
                                    ?
                                </span>
                            </h1>
                        </div>
                        <div className='bg-[#E4FFC8] w-[90vw] h-[80px] text-left mb-3'>
                            <h1 className='ml-4 w-[95%] mt-4 text-[15px] font-medium flex flex-row items-center'>Is My Dream Job the Best Fit for Me
                                <span className='text-[32px] ml-2 font-semibold'>
                                    ?
                                </span>
                            </h1>
                        </div>
                        <div className='bg-[#E4FFC8] w-[90vw] h-[80px] text-left mb-3'>
                            <h1 className='w-[95%] leading-[24px] ml-4 mt-3 text-[15px] font-medium items-center'>Was My Performance in the Interview Up To Par
                                <span className='text-[32px] -my-2 ml-2 font-semibold'>
                                    ?
                                </span>
                            </h1>
                        </div>
                        <div className='bg-[#E4FFC8] w-[90vw] h-[80px] text-left mb-3'>
                            <h1 className='leading-[24px] w-[95%] ml-4 mt-3 text-[15px] font-medium items-center'>What More Can I Do To Get My Dream Job
                                <span className='text-[32px] ml-2 font-semibold'>
                                    ?
                                </span>
                            </h1>
                        </div>
                        </>}
                    </div>
                <div className='sep2 w-[100vw] h-[32px] mt-6' />
                <div className='bg-white mt-10 flex flex-col text-center items-center'>
                    {current?<>
                    <h1 className='font-bold text-[25px] whitespace-nowrap'>Faster and Effective Scouting<br/>  with Our Premium<br/> Recruitment Software</h1>
                    <img src={software} className='w-[90vw] -mt-10' />
                        <div className='bg-[#E4FFC8] w-[90vw] -mt-10'>
                            <ul className='text-left'>
                                <li className='ml-4 mt-4 w-[95%] text-[15px] font-medium '>• Best Hire via AI-powered Stack Ranking</li>
                                <li className='ml-4 mt-4 w-[95%] text-[15px] font-medium '>• Unbiased Evaluation for Each Role</li>
                                <li className='ml-4 mt-4 w-[95%] text-[15px] font-medium '>• Offers Profiles That Matches Your Requirement</li>
                                <li className='ml-4 mt-4 w-[95%] text-[15px] font-medium mb-4'>• Under the Guidance of Employers, Hiring Right Fits for Decades.</li>
                            </ul>
                        </div>
                    </>:<>
                    <h1 className='font-bold text-[25px] whitespace-nowrap'>Best Job Demands Best Skills</h1>
                    <img src={demand} className='w-[90vw]' />
                    <div className='bg-[#E4FFC8] w-[90vw] mt-6'>
                        <ul className='text-left'>
                        <li className='ml-4 mt-4 text-[15px] w-[95%] font-medium'>• Connect To Over Hundreds of Jobs</li>
                        <li className='ml-4 mt-4 text-[15px] w-[95%] font-medium'>• Get a Personalized Professional Resume</li>
                        <li className='ml-4 mt-4 text-[15px] w-[95%] font-medium'>• Get Detailed Feedback</li>
                        <li className='ml-4 mt-4 text-[15px] w-[95%] font-medium mb-4'>• Upgrade Your Premium Services</li>
                        </ul>
                    </div>
                    </>}
                </div>
                <div className='sep2 w-[100vw] h-[32px] mt-10' />
                <div className='bg-white mt-6 flex flex-col items-center text-center'>
                    {current?<>
                    <h1 className='font-bold text-[25px]'>USP Of Jobs Territory </h1>
                    <div className='flex flex-row items-center mt-4'>
                        <div className='powerBox flex flex-col items-center w-[45vw] h-[168px]'>
                            <img src={pow1} className='w-[25vw] h-[55%] mt-6' />
                            <h1 className='font-semibold text-center mb-4'>100+<br/> Client Served</h1>
                        </div>
                        <div className='powerBox flex flex-col items-center ml-4 w-[45vw] h-[168px]'>
                            <img src={pow2} className='w-[25vw] h-[55%] mt-4'/>
                            <h1 className='mb-4 mt-2 font-semibold whitespace-nowrap text-center text-[12.3px]'>1146000+<br/>Active Candidate Database</h1>
                        </div>
                    </div>
                    <div className='flex flex-row items-center mt-4'>
                        <div className='powerBox flex flex-col items-center w-[45vw] h-[168px]'>
                            <img src={pow3} className='w-[25vw] h-[55%] mt-6' />
                            <h1 className='mb-4 mt-2 font-semibold whitespace-nowrap text-center text-[14px]'>850+<br/>Unique Roles Mapped</h1>
                        </div>
                        <div className='powerBox flex flex-col items-center ml-4 w-[45vw] h-[168px]'>
                            <img src={pow4} className='w-[25vw] h-[55%] mt-6'/>
                            <h1 className='mb-4 mt-2 font-semibold whitespace-nowrap text-center text-[14px]'>14+<br/>Industries Served</h1>
                        </div>
                    </div>
                    </>:<><h1 className='font-bold text-[25px]'>Our Success Story </h1>
                    <div className='flex flex-row items-center mt-4 w-[95vw]'>
                        <div className='powerBox flex flex-col items-center w-[45vw] h-[168px]'>
                            <img src={pow1} className='w-[25vw] h-[55%] mt-6' />
                            <h1 className='mt-2 text-[13px] font-semibold text-center mb-4'>50000+<br/>Job opportunities offered</h1>
                        </div>
                        <div className='powerBox flex flex-col items-center ml-4 w-[45vw] h-[168px]'>
                            <img src={pow2} className='w-[25vw] h-[55%] mt-4'/>
                            <h1 className='mb-4 mt-2 font-semibold whitespace-nowrap text-center text-[12.3px]'>10000+<br/>Candidates placed till now</h1>
                        </div>
                    </div>
                    <div className='flex flex-row items-center mt-4 w-[95vw]'>
                        <div className='powerBox flex flex-col items-center w-[45vw] h-[168px]'>
                            <img src={pow3} className='w-[25vw] h-[55%] mt-6' />
                            <h1 className='mb-4 mt-2 font-semibold whitespace-nowrap text-center text-[14px]'>8000+<br/>Satisfied customers</h1>
                        </div>
                        <div className='powerBox flex flex-col items-center ml-4 w-[45vw] h-[168px]'>
                            <img src={pow4} className='w-[25vw] h-[55%] mt-6'/>
                            <h1 className='mb-4 mt-2 font-semibold whitespace-nowrap text-center text-[14px]'>11+<br/>Industries Served</h1>
                        </div>
                    </div>
                    </>}
                </div>
                {current?
                <div className='mt-10'>
                    <Testimonials />
                </div>:null}
                <div className='mt-16'>
                    <Footer /> 
                </div>
            </div>   
        </div>
    </div>
    </MediaQuery>
    <MediaQuery query="(min-width: 540px)">
    <div className='font-poppins'>
        <div className='fixed w-[100vw] z-[200] bg-white '>
            <Navbar active="home"/>
        </div>
        <div className='flex flex-col items-center mx-5'/>
            <div className='bg w-[100vw] flex flex-row items-center'>
                <img src={newBg} className='-ml-[5vw] h-[90vh] w-[55vw] mb-0' />
                <div className='mt-28 -ml-[40vw] flex flex-col'>
                    <h1 className='font-[800] text-[64px] leading-[4vw] mainText h-[10rem]'>Bridging Between<br/> Seekers & Seeking</h1>
                    <h1 className='text-[22px] whitespace-nowrap secText'>Where Amazing Talents Meets Inspiring Visionary</h1>
                    <div className='z-10 bg-white rounded-[10px] w-[27rem] h-[4rem] flex flex-row items-center mt-20'>
                        <button className='w-[50%] h-[60%] recruiterSwitch ml-2' style={current?activeStyle:null} onClick={switchRole}>
                            EMPLOYER
                        </button>
                        <button className='w-[50%] h-[60%] candidateSwitch mr-2' style={!current?activeStyle:null} onClick={switchRole}>
                            CANDIDATE
                        </button>
                    </div>
                    <div className='z-10 bg-white rounded-[7px] w-[27rem] h-[3rem] mb-20 mt-10 flex flex-row' onClick={()=>!current?navigate('/comingSoon'):navigate('/services')}>
                        <input className='ml-3 text-[16px] w-11/12 outline-none border-none' placeholder={current?"Search For Talent":"Search for Jobs"}/>
                        <button className='ml-1 mr-2'>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.6226 16.7676L25.6163 23.7613C25.8621 24.0073 26.0001 24.3408 26 24.6886C25.9999 25.0363 25.8616 25.3698 25.6156 25.6156C25.3696 25.8614 25.0361 25.9995 24.6883 25.9994C24.3405 25.9992 24.0071 25.861 23.7613 25.615L16.7676 18.6213C14.6769 20.2406 12.0479 21.0026 9.41529 20.7524C6.78272 20.5021 4.34437 19.2583 2.59627 17.2741C0.84818 15.2898 -0.0783409 12.7141 0.00519498 10.071C0.0887309 7.42789 1.17605 4.91586 3.04595 3.04595C4.91586 1.17605 7.42788 0.0887309 10.071 0.00519498C12.7141 -0.0783409 15.2898 0.84818 17.2741 2.59627C19.2583 4.34437 20.5021 6.78272 20.7524 9.41529C21.0026 12.0479 20.2406 14.6769 18.6213 16.7676H18.6226ZM10.4005 18.1988C12.4691 18.1988 14.4529 17.3771 15.9157 15.9144C17.3784 14.4516 18.2001 12.4678 18.2001 10.3992C18.2001 8.33059 17.3784 6.34672 15.9157 4.88401C14.4529 3.42129 12.4691 2.59955 10.4005 2.59955C8.33189 2.59955 6.34802 3.42129 4.88531 4.88401C3.42259 6.34672 2.60085 8.33059 2.60085 10.3992C2.60085 12.4678 3.42259 14.4516 4.88531 15.9144C6.34802 17.3771 8.33189 18.1988 10.4005 18.1988Z" fill="#C7C6C6"/>
                            </svg>
                        </button>
                    </div>
                </div>
                {!current?<><img src={peopleNew} className='ml-[7vw] w-[45vw] h-[70vh] mt-32 mb-0' />
                </>:<div className='flex flex-col mb-0'>
                <img src={newServices} className='z-10 -mb-[15rem] ml-[43vh] w-[25vw] h-[27vh]'/>
                <img src={companyNew} className='ml-[5vw] w-[45vw] h-[80vh] -mb-[24vh]'/>
                </div>}
        </div>
        <a className='fixed right-[2rem] bottom-[5rem]' target='_blank'
        href='https://api.whatsapp.com/send/?phone=919686116232&text&type=phone_number&app_absent=0'>
            <img src={whatsapp} />
        </a>
        <div className='mt-4 flex flex-col items-center bg-[#F4FAFF] w-[100vw]'>
            {current?
                <span className='text-[1.5vw] mt-2 mb-2 font-semibold'>Trusted by 100+ Employers</span>
                :<span className='text-[1.5vw] mt-2 mb-2 font-semibold'>Trusted by 100+ Brands that hired our candidates</span>
                }
        </div>
        <div className='aliceLg flex items-center ml-[2vw] w-[100vw]'>
            <ClientLogos dir='ltr'/>
        </div>
        <div className='signUpBg mt-[13vh] w-[100vw] flex flex-col items-center'>
            {current?<>
            <strong className='mt-10 text-white font-semibold text-[1.25vw] text-center'>
                Take charge of your talent pool.Get Ready-to-Hire Talent with <br/>Jobs Territory.
            </strong>
            <button className='mt-10 w-[9vw] h-[5.2vh] bg-white rounded-xl mb-10' onClick={()=>navigate('/services')}>
                <span className='animation text-[0.93vw] '>SIGN UP NOW
                </span>
            </button>
            </>:<>
            <strong className='mt-10 text-white font-semibold text-[1.25vw] text-center'>
                Are You Ready For Your Dream Job? Let's Get Started.
            </strong>
            <button className='mt-10 w-[9vw] h-[5.2vh] bg-white rounded-xl mb-10' onClick={()=>navigate('/comingsoon')}>
                <span className='animation text-[0.93vw] '>SIGN UP NOW
                </span>
            </button>
            </>}
        </div>
        <div className='mt-[7vh] flex flex-col items-center text-center'>
            {current?<>
            <span className='text-[#23496A] text-[1.5vw] '>
            Make the right hires, faster with the <br/>
            <span className='text-[2vw] font-bold'>
                world's leading recruiting software
            </span>
            </span>
            <div className='flex flex-row mt-[3vh] items-center'>
                <div className='flex flex-col items-center text-center ml-10'>
                    <img src={img1} className='featureImg' />
                    <h1 className='text-[#23496A] text-[1.5vw] font-bold'>MACHINE LEARNING (AI)</h1>
                    <span className='font-normal text-[1vw] mt-1'>
                        <center className='font-semibold'>Find and attract<br/>
                        candidates</center><br/>
                        At the time of job listing, our AI-<br />powered sourcing generates the 
                        list of<br/> the best prospective candidates as per<br /> the skill requirement.
                    </span>
                </div>
                <div className='flex flex-col items-center text-center mr-0'>
                    <img src={img2} className='featureImg' />
                    <h1 className='text-[#23496A] text-[1.5vw] font-bold'>MOTIVATE & ANALYSE</h1>
                    <span className='font-normal text-[1vw] mt-1'>
                        <center className='font-semibold'>Putting the potential personnel<br/>
                        forward</center><br />
                        From collaborating with hiring teams to<br/> evaluate applicants to collecting
                        <br/>feedback and deciding who is best. It is<br />an all in one 
                        recruiting system.
                    </span>
                </div>
                <div className='flex flex-col items-center text-center'>
                    <img src={img3} className='featureImg' />
                    <h1 className='text-[#23496A] text-[1.5vw] font-bold'>RECRUIT & OPTIMISE</h1>
                    <span className='font-normal text-[1vw] mt-1'>
                        <center className='font-semibold'>Effective Hiring decision in<br/>
                        half the time</center><br/>
                        Work efficiently with a well-guided<br/>recruiting platform. 
                        It simplifies the<br/> process and manual tasks such as<br/> scheduling, 
                        interviews and approval. 
                    </span>
                </div>
            </div>
                </>:<>
                <span className='text-[#23496A] text-[1.5vw] '>
                    Upgrade Your Career With Right Skills and Profiles <br/>
                <span className='text-[2vw] font-bold'>
                    Jobs That Fit You The Best
                </span>
                </span>
                <div className='flex flex-row -mt-10 items-center'>
                    <div className='flex flex-col items-center text-center ml-10'>
                        <img src={can1} className='w-[35vw] h-[35vh] mb-8' />
                        <h1 className='font-medium text-center text-[#23496A] text-[1.2vw] -mt-6'>
                            Transparency Throughout<br/> the Process
                        </h1>
                    </div>
                    <div className='flex flex-col items-center text-center ml-16 mr-0'>
                        <img src={can2} className='w-[35vw] h-[35vh] mt-10 ' />
                        <h1 className='font-medium text-center text-[#23496A] text-[1.2vw] '>
                            Job Proposals That Suits<br/> Best To Your Profile
                        </h1>
                    </div>
                    <div className='flex flex-col items-center text-center ml-16'>
                        <img src={can3} className='w-[35vw] h-[35vh] mt-8 mb-0' />
                        <h1 className='font-medium text-center text-[#23496A] text-[1.2vw] '>
                            In-depth Feedback For<br/> Your Optimal Performance
                        </h1>
                    </div>
            </div>
            </>}
        </div>
        <div className='signUpBg mt-14 w-[100vw] flex flex-col items-center'>
            {!current?
            <strong className='mt-4 text-white font-semibold text-[2.4vw] text-center'>
                Browse As An Employer
            </strong>:
            <strong className='mt-4 text-white font-semibold text-[2.4vw] text-center'>
                Browse As A Candidate
            </strong>
            }
            <div className='z-10 bg-white rounded-[10px] w-[27rem] h-[4rem] flex flex-row items-center mt-4 mb-4'>
                <button className='w-[50%] h-[60%] recruiterSwitch ml-2' style={current?activeStyle:null} onClick={switchRole}>
                    EMPLOYER
                </button>
                <button className='w-[50%] h-[60%] candidateSwitch mr-2' style={!current?activeStyle:null} onClick={switchRole}>
                    CANDIDATE
                </button>
            </div>
        </div>
        <div className='bg-white mt-2 flex flex-row items-center'>
            {current?<>
            <img src={challenge} className='w-[30vw] ml-[15vw] mt-20' />
            <div className='flex flex-col items-center text-center ml-10 mt-8'>
                <h1 className='font-bold text-[2.3vw] leading-[50px] mb-4'>Why Recruiting Has Become<br/>Challenging?</h1>
                <div className='bg-[#E4FFC8] w-[40vw] h-[5rem] text-left mb-4'>
                    <h1 className='ml-6  mt-4 text-[1vw] font-medium whitespace-nowrap'>Urgency! Shortage of Time Leads to a Shortage of Talented Hires.</h1>
                </div>
                <div className='bg-[#E4FFC8] w-[40vw] h-[5rem] text-left mb-4'>
                    <h1 className='ml-6  mt-4 text-[1vw] font-medium whitespace-nowrap'>Unorganized and Subpar Applicant Info</h1>
                </div>
                <div className='bg-[#E4FFC8] w-[40vw] h-[5rem] text-left mb-4'>
                    <h1 className='ml-6  mt-4 text-[1vw] font-medium whitespace-nowrap'>Massive Piles of Untapped Resumes.</h1>
                </div>
                <div className='bg-[#E4FFC8] w-[40vw] h-[5rem] text-left'>
                    <h1 className='ml-6 mt-4 text-[1vw] font-medium whitespace-nowrap'>Long Exhausting Screening</h1>
                </div>
            </div>
            </>:<>
                <img src={scout} className='w-[35vw] ml-[15vw] mt-32' />
                <div className='flex flex-col items-center text-center -ml-10 mt-8'>
                    <h1 className='font-bold text-[2.3vw] leading-[50px] mb-4 -ml-10'>Often Find Yourself<br/> Wondering While<br/> Scouting Your Dream Job</h1>
                    <div className='bg-[#E4FFC8] w-[30vw] h-[5rem] text-left mb-3 ml-10'>
                        <h1 className='ml-6  mt-4 text-[1vw] font-medium whitespace-nowrap flex flex-row items-center'>How Can I Be My Best Professional Self
                            <span className='ml-4'>
                            <svg width="23" height="35" viewBox="0 0 23 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.576 0.159998C14.84 0.159998 17.448 1.056 19.4 2.848C21.384 4.64 22.376 7.088 22.376 10.192C22.376 13.424 21.352 15.856 19.304 17.488C17.256 19.12 14.536 19.936 11.144 19.936L10.952 23.728H6.2L5.96 16.192H7.544C10.648 16.192 13.016 15.776 14.648 14.944C16.312 14.112 17.144 12.528 17.144 10.192C17.144 8.496 16.648 7.168 15.656 6.208C14.696 5.248 13.352 4.768 11.624 4.768C9.896 4.768 8.536 5.232 7.544 6.16C6.552 7.088 6.056 8.384 6.056 10.048H0.92C0.92 8.128 1.352 6.416 2.216 4.912C3.08 3.408 4.312 2.24 5.912 1.408C7.544 0.575998 9.432 0.159998 11.576 0.159998ZM8.504 34.336C7.512 34.336 6.68 34 6.008 33.328C5.336 32.656 5 31.824 5 30.832C5 29.84 5.336 29.008 6.008 28.336C6.68 27.664 7.512 27.328 8.504 27.328C9.464 27.328 10.28 27.664 10.952 28.336C11.624 29.008 11.96 29.84 11.96 30.832C11.96 31.824 11.624 32.656 10.952 33.328C10.28 34 9.464 34.336 8.504 34.336Z" fill="black"/>
                            </svg>
                            </span>
                        </h1>
                    </div>
                    <div className='bg-[#E4FFC8] w-[30vw] h-[5rem] text-left mb-3 ml-10'>
                        <h1 className='ml-6  mt-4 text-[1vw] font-medium whitespace-nowrap flex flex-row items-center'>Is My Dream Job the Best Fit for Me
                            <span className='ml-4'>
                            <svg width="23" height="35" viewBox="0 0 23 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.576 0.159998C14.84 0.159998 17.448 1.056 19.4 2.848C21.384 4.64 22.376 7.088 22.376 10.192C22.376 13.424 21.352 15.856 19.304 17.488C17.256 19.12 14.536 19.936 11.144 19.936L10.952 23.728H6.2L5.96 16.192H7.544C10.648 16.192 13.016 15.776 14.648 14.944C16.312 14.112 17.144 12.528 17.144 10.192C17.144 8.496 16.648 7.168 15.656 6.208C14.696 5.248 13.352 4.768 11.624 4.768C9.896 4.768 8.536 5.232 7.544 6.16C6.552 7.088 6.056 8.384 6.056 10.048H0.92C0.92 8.128 1.352 6.416 2.216 4.912C3.08 3.408 4.312 2.24 5.912 1.408C7.544 0.575998 9.432 0.159998 11.576 0.159998ZM8.504 34.336C7.512 34.336 6.68 34 6.008 33.328C5.336 32.656 5 31.824 5 30.832C5 29.84 5.336 29.008 6.008 28.336C6.68 27.664 7.512 27.328 8.504 27.328C9.464 27.328 10.28 27.664 10.952 28.336C11.624 29.008 11.96 29.84 11.96 30.832C11.96 31.824 11.624 32.656 10.952 33.328C10.28 34 9.464 34.336 8.504 34.336Z" fill="black"/>
                            </svg>
                            </span>
                        </h1>
                    </div>
                    <div className='bg-[#E4FFC8] w-[30vw] h-[5rem] text-left mb-3 ml-10'>
                        <h1 className='ml-6  mt-4 text-[1vw] font-medium whitespace-nowrap flex flex-row items-center'>Was My Performance in the Interview Up To Par
                            <span className='ml-4'>
                            <svg width="23" height="35" viewBox="0 0 23 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.576 0.159998C14.84 0.159998 17.448 1.056 19.4 2.848C21.384 4.64 22.376 7.088 22.376 10.192C22.376 13.424 21.352 15.856 19.304 17.488C17.256 19.12 14.536 19.936 11.144 19.936L10.952 23.728H6.2L5.96 16.192H7.544C10.648 16.192 13.016 15.776 14.648 14.944C16.312 14.112 17.144 12.528 17.144 10.192C17.144 8.496 16.648 7.168 15.656 6.208C14.696 5.248 13.352 4.768 11.624 4.768C9.896 4.768 8.536 5.232 7.544 6.16C6.552 7.088 6.056 8.384 6.056 10.048H0.92C0.92 8.128 1.352 6.416 2.216 4.912C3.08 3.408 4.312 2.24 5.912 1.408C7.544 0.575998 9.432 0.159998 11.576 0.159998ZM8.504 34.336C7.512 34.336 6.68 34 6.008 33.328C5.336 32.656 5 31.824 5 30.832C5 29.84 5.336 29.008 6.008 28.336C6.68 27.664 7.512 27.328 8.504 27.328C9.464 27.328 10.28 27.664 10.952 28.336C11.624 29.008 11.96 29.84 11.96 30.832C11.96 31.824 11.624 32.656 10.952 33.328C10.28 34 9.464 34.336 8.504 34.336Z" fill="black"/>
                            </svg>
                            </span>
                        </h1>
                    </div>
                    <div className='bg-[#E4FFC8] w-[30vw] h-[5rem] text-left mb-3 ml-10'>
                        <h1 className='ml-6  mt-4 text-[1vw] font-medium whitespace-nowrap flex flex-row items-center'>What More Can I Do To Get My Dream Job
                            <span className='ml-4'>
                            <svg width="23" height="35" viewBox="0 0 23 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.576 0.159998C14.84 0.159998 17.448 1.056 19.4 2.848C21.384 4.64 22.376 7.088 22.376 10.192C22.376 13.424 21.352 15.856 19.304 17.488C17.256 19.12 14.536 19.936 11.144 19.936L10.952 23.728H6.2L5.96 16.192H7.544C10.648 16.192 13.016 15.776 14.648 14.944C16.312 14.112 17.144 12.528 17.144 10.192C17.144 8.496 16.648 7.168 15.656 6.208C14.696 5.248 13.352 4.768 11.624 4.768C9.896 4.768 8.536 5.232 7.544 6.16C6.552 7.088 6.056 8.384 6.056 10.048H0.92C0.92 8.128 1.352 6.416 2.216 4.912C3.08 3.408 4.312 2.24 5.912 1.408C7.544 0.575998 9.432 0.159998 11.576 0.159998ZM8.504 34.336C7.512 34.336 6.68 34 6.008 33.328C5.336 32.656 5 31.824 5 30.832C5 29.84 5.336 29.008 6.008 28.336C6.68 27.664 7.512 27.328 8.504 27.328C9.464 27.328 10.28 27.664 10.952 28.336C11.624 29.008 11.96 29.84 11.96 30.832C11.96 31.824 11.624 32.656 10.952 33.328C10.28 34 9.464 34.336 8.504 34.336Z" fill="black"/>
                            </svg>
                            </span>
                        </h1>
                    </div>
                </div>
            </>}
        </div>
        <div className='sep w-[100vw] h-[1.25rem] mt-8' />
        <div className='bg-white mt-0 flex flex-row items-center'>
            {current?<>
            <img src={software} className='w-[37vw] h-[77vh] ml-[12vw] -mb-40' />
            <div className='flex flex-col items-center text-center -ml-4 mt-8 mb-0'>
                <h1 className='font-bold text-[2.3vw] leading-[50px] mb-4'>Faster and Effective<br/> Scouting with Our<br/> Premium Recruitment<br/> Software</h1>
                <div className='bg-[#E4FFC8] w-[40vw] mt-0'>
                    <ul className='text-left'>
                        <li className='ml-6 mt-4 text-[1vw] font-medium whitespace-nowrap'>• Best Hire via AI-powered Stack Ranking</li>
                        <li className='ml-6 mt-4 text-[1vw] font-medium whitespace-nowrap'>• Unbiased Evaluation for Each Role</li>
                        <li className='ml-6 mt-4 text-[1vw] font-medium whitespace-nowrap'>• Offers Profiles That Matches Your Requirement</li>
                        <li className='ml-6 mt-4 text-[1vw] font-medium whitespace-nowrap mb-4'>• Under the Guidance of Employers, Hiring Right Fits<br/> for Decades.</li>
                    </ul>
                </div>
            </div>
            </>:<>
            <img src={demand} className='w-[30vw] h-[45vh] ml-[15vw] -mb-40 pb-10' />
            <div className='flex flex-col items-center text-center  mt-10 mb-0'>
                <h1 className='font-bold text-[2.3vw] leading-[50px] mb-4'>Best Job Demands<br/> Best Skills</h1>
                <div className='bg-[#E4FFC8] w-[40vw] mt-0 mb-10'>
                    <ul className='text-left'>
                    <li className='ml-6  mt-4 text-[1vw] font-medium whitespace-nowrap'>• Connect To Over Hundreds of Jobs</li>
                    <li className='ml-6  mt-4 text-[1vw] font-medium whitespace-nowrap'>• Get a Personalized Professional Resume</li>
                    <li className='ml-6  mt-4 text-[1vw] font-medium whitespace-nowrap'>• Get Detailed Feedback</li>
                    <li className='ml-6 mt-4 text-[1vw] font-medium whitespace-nowrap mb-4'>• Upgrade Your Premium Services</li>
                    </ul>
                </div>
            </div>
            </>}
        </div>
        <div className='sep w-[100vw] h-[1.25em] mt-10' />
        <div className='bg-white mt-6 flex flex-col items-center'>
            {current?<>
            <h1 className='font-bold text-[2.3vw] mb-4'>USP Of Jobs Territory </h1>
            <div className='flex flex-row items-center mt-4'>
                <div className='powerBox flex flex-col items-center w-[15vw] h-[20vh]'>
                    <img src={pow1} className='w-[6vw] h-[8vh] mt-3' />
                    <h1 className='mt-4 font-semibold text-center'>100+<br/> Client Served</h1>
                </div>
                <div className='powerBox flex flex-col items-center ml-20 w-[15vw] h-[20vh]'>
                    <img src={pow2} className='w-[6vw] h-[8vh] mt-3'/>
                    <h1 className='mt-3 font-semibold text-center'>1146000+<br/>Active Candidate<br/> Database</h1>
                </div>
                <div className='powerBox flex flex-col items-center ml-20 w-[15vw] h-[20vh]'>
                    <img src={pow3} className='w-[6vw] h-[8vh] mt-3' />
                    <h1 className='mt-4 font-semibold text-center'>850+<br/>Unique Roles Mapped</h1>
                </div>
                <div className='powerBox flex flex-col items-center ml-20 w-[15vw] h-[20vh]'>
                    <img src={pow4} className='w-[6vw] h-[8vh] mt-3'/>
                    <h1 className='mt-4 font-semibold text-center'>14+<br/>Industries Served</h1>
                </div>
            </div>
            </>:<>
            <h1 className='font-bold text-[2.3vw] mb-4'>Our Success Story</h1>
            <div className='flex flex-row items-center mt-4'>
                <div className='powerBox flex flex-col items-center w-[15vw] h-[20vh]'>
                    <img src={pow1} className='w-[6vw] h-[8vh] mt-3' />
                    <h1 className='mt-4 font-semibold text-center'>50000+<br/>Job opportunities offered</h1>
                </div>
                <div className='powerBox flex flex-col items-center ml-20 w-[15vw] h-[20vh]'>
                    <img src={pow2} className='w-[6vw] h-[8vh] mt-3'/>
                    <h1 className='mt-3 font-semibold text-center'>10000+<br/>Candidates placed till<br/> now.</h1>
                </div>
                <div className='powerBox flex flex-col items-center ml-20 w-[15vw] h-[20vh]'>
                    <img src={pow3} className='w-[6vw] h-[8vh] mt-3' />
                    <h1 className='mt-4 font-semibold text-center'>8000+<br/>Satisfied customers</h1>
                </div>
                <div className='powerBox flex flex-col items-center ml-20 w-[15vw] h-[20vh]'>
                    <img src={pow4} className='w-[6vw] h-[8vh] mt-3'/>
                    <h1 className='mt-4 font-semibold text-center'>11+<br/>Industries Served</h1>
                </div>
            </div>
            </>
            }
        </div>
        {current?
        <div className='mt-14 z-50'>
            <TestimonialsDesktop />
        </div>:null}
        <div className='mt-10 bg-[#D3DBE1] w-[100vw] flex flex-col text-center'>
            <h1 className='font-bold text-[1.5vw] mt-3'>The Jobs Territory Network</h1>
            {current?
            <h3 className='font-medium text-[1.2vw] mt-2 mb-3'>
                    We are because they are.
                <button className='ml-2 text-[#84C441]'>
                    EXPLORE ABOUT US
                </button>
            </h3>:
            <h3 className='font-medium text-[1.2vw] mt-2 mb-3'>
                Our Networks you can benefit with.
                <button className='ml-2 text-[#84C441]'> 
                    EXPLORE NOW
                </button>
            </h3>
            }
        </div>
        <div className='aliceLg flex items-center w-[100vw] ml-[2vw]'>
            <ClientLogos dir='ltr' />
        </div> 
        <div className='mt-[14vh]'>
            <FooterDesktop />
        </div>
    </div>
    </MediaQuery>
    </>
  )
}

export default Home;