import React from "react";
import Footer from "../components/Home/Footer";
import FooterDesktop from '../components/Home/FooterDesktop';
import TestimonialsDesktop from '../components/Home/TestimonialsDesktop';
import MediaQuery from "react-responsive";
import ClientLogos from "../components/Home/ClientLogos";
import Testimonials from "../components/Home/Testimonials";
import HypeComp from "../components/HypeComp";
import IndustriesCater from "../components/IndustriesCater";
import Navbar from "../components/Navbar";
import OptUs from "../components/OptUs";
import Requirements from "../components/Requirements";
import img1 from "../media//image 11.png";
import bgImg from "../media/image 78.png";

import img61 from "../media/image 81.png";
import img62 from "../media/image 82.png";
import img65 from "../media/image 83.png";
import img66 from "../media/image 84.png";

function Pph() {
  return (
    <div className=" font-poppins">
      <div className='fixed w-[100vw] z-[200] bg-white '>
            <Navbar active="recr"/>
        </div>
      <div className=" bg-gradient-to-br from-[#84C441]/30 via-[#74CA86]/30 to-[#65D0CA]/30 h-[40vh] lg:clip-style-2 lg:w-[30vw]  clip-style -z-10"></div>
      <div className="z-10 flex flex-col items-center pt-2 mx-5 -mt-80">
        <div className=" bg-[#EBFFD6] p-6 rounded-full z-10 mt-14 lg:mt-24">
          <img src={img1} alt="" className="z-10" />
        </div>
        <h1 className="mt-3 font-bold text-xl text-[#23496A] z-10 ">
          Non Tech Hiring{" "}
        </h1>
        <img
          src={bgImg}
          alt=""
          className="z-10 mx-10 mt-[2vw] shadow-md shadow-gray-600"
        />
        <h1 className="mt-4 text-sm lg:text-center lg:text-[0.9vw] lg:leading-[24px] lg:mt-7 lg:text-base lg:mx-[30vw]">
          The current recruiting system fails to meet the ongoing challenges and
          dynamic trends, making it difficult to cast appropriate candidates for
          the profile. Our non tech hiring solutions is a revolutionized
          recruiting system, making hiring goals easier, more flexible, and
          cost-effective.
        </h1>
      </div>
      <div className="pb-2 ">
        <Requirements />
      </div>
      <div className="py-4 ">
        <OptUs text="Whether it is an arduous job or a large number of openings or scouting around to get high-caliber personnel, non tech hire system is always a rescue. Organizations simply have to put forward the job profile and project requirements, in return for which recruiting agency simply deliver their candidates to operate for them." />
      </div>
      <div className="flex flex-col items-center px-4 py-3">
        <h1 className=" text-[#84C441] text-2xl text-center font-bold tracking-wide ">
          WHAT'S THE HYPE BEHIND JOB TERRITORY'S NON TECH HIRING?
        </h1>
        <div className="grid grid-cols-2 mt-8 lg:grid-cols-4 gap-x-10 lg:gap-x-36 gap-y-7">
          <HypeComp
            img={img61}
            text="Save your time by sending qualified and skilled personnel"
          />
          <HypeComp img={img62} text="No more scouting around!" />
          <HypeComp
            img={img65}
            text="Highly qualified employees for your position"
          />
          <HypeComp
            img={img66}
            text="No hush of payments. Only pay if you make a hire"
          />
        </div>
      </div>
      <IndustriesCater />
      <div className="mt-10">
      <MediaQuery query="(max-width: 480px)">
        <div className="bg-gradient-to-l from-[#84C441] via-[#74CA86] to-[#65D0CA] w-[100vw] flex space-y-5 flex-col items-center py-2">
          <h1 className="text-white text-2xl font-bold">OUR CLIENTS</h1>
        </div>
        <div className="aliceSm w-[100vw] flex flex-col items-center">
          <div className="w-[90vw] mt-3">
            <ClientLogos dir='ltr' mid='true'/>
          </div>
          <div className="w-[90vw] mt-2">
            <ClientLogos dir='rtl' mid='false'/>
          </div>
        </div>
        <div className="mt-3">
            <Testimonials />
        </div>
        <div className="mt-10">
            <Footer/>
        </div>
      </MediaQuery>
      <MediaQuery query="(min-width: 540px)">
        <div className="aboutClients w-[100vw] flex space-y-5 flex-col items-center py-2">
          <h1 className="text-white text-[36px] font-bold">OUR CLIENTS</h1>
        </div>
        <div className="aliceLg flex items-center w-[100vw] ml-[2vw]">
          <ClientLogos dir='ltr' />
        </div>
        <div className="mt-[14vh]">
          <TestimonialsDesktop />
        </div>
        <div className="mt-[8vh]">
          <FooterDesktop />
        </div>
      </MediaQuery>
      </div>
    </div>
  );
}

export default Pph;
