import React from 'react'
import './search.css'

const SearchCard = ({show,data}) => {

    return (
        <>
            <div className="search-result-card-container">
                <div className="pfp-image">
                    {/* <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M35.5 0C15.904 0 0 15.904 0 35.5C0 55.096 15.904 71 35.5 71C55.096 71 71 55.096 71 35.5C71 15.904 55.096 0 35.5 0ZM35.5 14.2C42.3515 14.2 47.925 19.7735 47.925 26.625C47.925 33.4765 42.3515 39.05 35.5 39.05C28.6485 39.05 23.075 33.4765 23.075 26.625C23.075 19.7735 28.6485 14.2 35.5 14.2ZM35.5 63.9C28.2935 63.9 19.7735 60.989 13.703 53.676C19.9212 48.7973 27.5964 46.1458 35.5 46.1458C43.4036 46.1458 51.0788 48.7973 57.297 53.676C51.2265 60.989 42.7065 63.9 35.5 63.9Z" fill="#F8F8F8" />
                </svg> */}
                </div>
                <div className="card-title">{data.Title}</div>
                {/* <div className="name">Name : {"A#### Yadav"}</div>
                <div className="id">ITC: 9008765430021</div>
                <div className="job-title">Job Title: UI/UX Design</div> */}
               
                <div className="card-top-skills">Top Skills</div>
                {
                    data["Top Skills"].map((data)=>{
                        return(
                            <div className="card-top-skills-name" >{data}</div>
                        )
                    })
                }
                <div className="card-top-skills" style={{top:'6vh'}}>Required Experience</div>
                <div className="card-top-skills-name" style={{top:'6vh'}}>{data["Required Experience"]}</div>
                <button className="apply-now-button btn btn-success" onClick={show}>Apply Now</button>
            </div>
        </>
    )
}

export default SearchCard;