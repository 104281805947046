import React,{useState} from 'react'
import './search.css';
import SearchCard from './SearchCard';
import Footer from './Footer';
import Form from './Form';
import Testimonials from './Testimonials';
import Navbar from './Navbar.jsx'
import ClientLogos from './ClientLogos';
import ConfirmModal from './ConfirmModal';
import { MobileOnlyView,BrowserView } from 'react-device-detect';
import axios from 'axios';

const Search = () => {

    const [show,setShow] = useState(false);
    const [job,setJob] = useState("");
    const [jobsArray,setJobsArray] = useState([]);
    const [totalJobs,setTotalJobs] = useState(0);

    const confirmApplication = () => {
        setShow(true);
    }

    const closeModal = () => {
        setShow(false);
    }

    const getData = async() => {
        
    }

    const searchFunction = (data) => {
         axios.post("https://jobs-territory-api.herokuapp.com/jobsterritory/jobs",{
            "title":data
                })
                .then((res) =>{ setJobsArray(res.data["Jobs Posted"]); setTotalJobs(res.data["Total Jobs"])})
                .catch((err)=>console.error(err))
        setJob(data);
    }

    return (
        <>
            {/* <div className="navbar" style={{ width: 'auto', height: '57px', position: 'relative', border: '1px solid black' }}></div> */}
            <Navbar />
            <div className="" style={{ position: 'relative' }}>
                <div className="recruiterDropdown dropdown" style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                    <button className="btn btn-success dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Candidate</button>
                </div>
                {/* <ul className="dropdown-menu">
                        <li><a class="dropdown-item" href="#">Action</a></li>
                        <li><a class="dropdown-item" href="#">Another action</a></li>
                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                </div> */}
                <div className="candidateProfileText" style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                    <h1 className="first">Job profiles for <p className='second'>UI/UX Design</p> </h1>
                </div>
            </div>
            <div className="container search-screen-container">
                <div className="row" >
                    <div className="col-md-auto" style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <input type="text" className="search-screen-input form-control" onChange={(e)=>searchFunction(e.target.value)}/>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6226 16.7676L25.6163 23.7613C25.8621 24.0073 26.0001 24.3408 26 24.6886C25.9999 25.0363 25.8616 25.3698 25.6156 25.6156C25.3696 25.8614 25.0361 25.9995 24.6883 25.9994C24.3405 25.9992 24.0071 25.861 23.7613 25.615L16.7676 18.6213C14.6769 20.2406 12.0479 21.0026 9.41529 20.7524C6.78272 20.5021 4.34437 19.2583 2.59627 17.2741C0.84818 15.2898 -0.0783409 12.7141 0.00519498 10.071C0.0887309 7.42789 1.17605 4.91586 3.04595 3.04595C4.91586 1.17605 7.42788 0.0887309 10.071 0.00519498C12.7141 -0.0783409 15.2898 0.84818 17.2741 2.59627C19.2583 4.34437 20.5021 6.78272 20.7524 9.41529C21.0026 12.0479 20.2406 14.6769 18.6213 16.7676H18.6226ZM10.4005 18.1988C12.4691 18.1988 14.4529 17.3771 15.9157 15.9144C17.3784 14.4516 18.2001 12.4678 18.2001 10.3992C18.2001 8.33059 17.3784 6.34672 15.9157 4.88401C14.4529 3.42129 12.4691 2.59955 10.4005 2.59955C8.33189 2.59955 6.34802 3.42129 4.88531 4.88401C3.42259 6.34672 2.60085 8.33059 2.60085 10.3992C2.60085 12.4678 3.42259 14.4516 4.88531 15.9144C6.34802 17.3771 8.33189 18.1988 10.4005 18.1988Z" fill="#C7C6C6" />
                        </svg>
                    </div>
                    <div className="col-md-auto">
                        {/* <button className="btn btn-success filter-button"><svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.4444 0H1.55556C1.143 0 0.747335 0.166354 0.455612 0.462467C0.163889 0.758579 0 1.16019 0 1.57896V5.66847C0 6.49426 0.331334 7.30585 0.906889 7.89006L9.33333 16.4433V28.4213C9.33364 28.6903 9.40158 28.9548 9.53073 29.1897C9.65988 29.4246 9.84596 29.6221 10.0714 29.7637C10.2967 29.9052 10.554 29.9859 10.8187 29.9983C11.0835 30.0107 11.347 29.9543 11.5842 29.8344L17.8064 26.6765C18.3338 26.4081 18.6667 25.8618 18.6667 25.2634V16.4433L27.0931 7.89006C27.6687 7.30585 28 6.49426 28 5.66847V1.57896C28 1.16019 27.8361 0.758579 27.5444 0.462467C27.2527 0.166354 26.857 0 26.4444 0ZM16.0113 14.6733C15.8666 14.8197 15.7518 14.9936 15.6736 15.1852C15.5954 15.3768 15.5553 15.5822 15.5556 15.7896V24.2876L12.4444 25.8665V15.7896C12.4447 15.5822 12.4046 15.3768 12.3264 15.1852C12.2482 14.9936 12.1334 14.8197 11.9887 14.6733L3.11111 5.66847V3.15792H24.8904L24.8936 5.65741L16.0113 14.6733Z" fill="#565656" />
                        </svg>
                        </button> */}
                    </div>
                </div>
            </div>
            <h3 className="resultTitle">{totalJobs} Profiles for {job}</h3>
            <div className="search-result-container">
                {
                    jobsArray.map((data)=>{
                        return(
                            <SearchCard show={confirmApplication}  data={data}/>
                        )
                    })
                }
            </div>
            <ConfirmModal show={show} close={closeModal}/>
            <MobileOnlyView>
            <div className="sign-up-container">
                <div className="stat-container">
                    <div className="stat-title">{'>85%'}</div>
                    <div className="stat-text">Relevancy of Qualified CVs</div>
                </div>
                <div className="stat-container">

                    <div className="stat-title">{'<30 days'}</div>
                    <div className="stat-text">To first Offer Candidate</div>
                </div>
                <div className="stat-container">
                    <div className="stat-title">{'3000+'}</div>
                    <div className="stat-text">Recruiters in Jobs Territory Club</div>

                </div>
            </div>
            </MobileOnlyView>
            <BrowserView>
            <div className="sign-up-container">
                <div className="stat-container">
                    <div className="stat-title">{'>85%'}</div>
                    <div className="stat-text">Relevancy of Qualified CVs</div>
                </div>
                <div className="stat-container">

                    <div className="stat-title">{'<30 days'}</div>
                    <div className="stat-text">To first Offer Candidate</div>
                </div>
                <div className="stat-container">
                    <div className="stat-title">{'3000+'}</div>
                    <div className="stat-text">Recruiters in Jobs Territory Club</div>

                </div>
                <div className="stat-container">
                    <div className="stat-title">{'3000+'}</div>
                    <div className="stat-text">Recruiters in Jobs Territory Club</div>

                </div>
                <div className="stat-container">
                    <div className="stat-title">{'3000+'}</div>
                    <div className="stat-text">Recruiters in Jobs Territory Club</div>
                </div>
            </div>
            </BrowserView>
            <MobileOnlyView>
            <Testimonials />
            </MobileOnlyView>
            <BrowserView>
            </BrowserView>
            <div className="client-carousel">
                <h1 className='search-clientsTitle'>
                    The Jobs Territory Network
                </h1>
                <h3 className='search-clientsText'>
                    We are because they are.
                    <button style={{ color: '#84C441', border: 'none' }}>
                        EXPLORE ABOUT US
                    </button>
                </h3>
                <div className='search-clients-2'>
                    <ClientLogos dir='ltr' />
                </div>
            </div>
            <Form />
            <Footer />
        </>
    )
}

export default Search;