import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "./TeamTestimonials.css";
import axios from "axios";
import jt from "../media/logo.png";
import ReadMore from './ReadMore';
import TestimonialModal from '../components/Home/TestimonialModal';


const Testimonials = () => {
  const [read,setRead] = useState(false);
  const [data, setdata] = useState([]);
  const [testimonialData,setTestimonialData] = useState(undefined);
  const testData = async () => {
    const getdata = await axios.get(
      "https://jobsterritorybackend.herokuapp.com/teamTestimonials"
    );
    const parsedData = [...getdata.data];
    parsedData.sort((test1, test2)=>{
      return ((test1.priority != undefined) ? +test1.priority : 2) - ((test2.priority != undefined) ? +test2.priority : 2);
    });
    setdata(parsedData);
  };
  useEffect(() => {
    testData();
  }, []);

  return (
    <div className="w-[100vw] h-[610px] flex flex-col items-center cardBg text-center">
        <h1 className="font-bold text-[26px] text-white mt-2 mb-4">
          What JTians have
          <br /> to say
        </h1>
        {read?<TestimonialModal show={read} item={testimonialData} setRead={setRead}/>:
        <Carousel>
          {data.map((item) => {
            return (
              <Carousel.Item key={item._id}>
                <div className="cardCenter text-left">
                    <p className="cardReview">{item.review.length>100?
                      (item.review.slice(0,100)+'...'
                        ):(
                          item.review
                        )}
                        </p>
                  <p className="cardDesignation">
                    <span style={{ fontWeight: "700" }}>{item.name}</span>
                    <br />
                    {item.designation.length>30?(item.designation.match(/(\b\w)?/g).join("").toUpperCase()):item.designation}
                  </p>
                  <img src={item.imageUrl} className="cardClientImage" />
                  <svg width="137" height="68" viewBox="0 0 137 68" fill="none" xmlns="http://www.w3.org/2000/svg"
                    className="cardLogoBg">
                    <circle cx="81.6719" cy="82.4688" r="81.5" fill="white" />
                  </svg>
                  <img src={jt} className="cardCompanyLogo" />
                  {(item.review.length>100 || item.designation.length>30)&&<button className='cardReadMore' onClick={()=>{setTestimonialData(item);setRead(true);console.log(`click`)}}
                  >READ MORE...</button>}
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
        }
    </div>
  );
};

export default Testimonials;
