import React,{useState} from "react";
import logo from "../../media/logogif.gif";
import { GiHamburgerMenu } from "react-icons/gi";
import NavMenu from "./NavMenu";
import MediaQuery from "react-responsive";

import { BiChevronDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const Navbar = ({ active }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  let navigate = useNavigate();

  const styles = (active==="wt")
  ? "uppercase bg-[#E9158E] px-4 rounded-xl text-white py-2 "
  : "uppercase bg-[#84C441] px-4 rounded-xl text-white py-2 "; 
  
  const loginStyle = (active==="wt")
  ? "border-[#E9158E] text-[#E9158E] hover:bg-[#E9158E] hidden px-4 py-1 uppercase lg:flex items-center border-2 rounded-xl transition-all hover:text-white"
  : "border-[#84C441] text-[#84C441] hover:bg-[#84C441] hidden px-4 py-1 uppercase lg:flex items-center border-2 rounded-xl transition-all hover:text-white";

  return (
    <>
      <div className="flex lg:h-[4.5rem] h-[3rem] items-center justify-between p-0 ml-4 mr-4 border-b-2 lg:px-40 lg:py-6 font-poppins">
      <MediaQuery query="(max-width: 480px)">
        <img src={logo} alt="" className="w-16" onClick={() => {
                navigate("/");
              }}/>
      </MediaQuery>
      <MediaQuery query="(min-width: 1036px)">
        <img src={logo} alt="" className="ml-28 mb-0 pb-0 my-2 w-[143px] h-[100px]" onClick={() => {
                navigate("/");
              }}/>
      </MediaQuery>
        <div className="flex items-center space-x-5 lg:mr-10">
          <div className="hidden space-x-14 lg:flex">
          <button className="text-[0.9vw] uppercase cursor-pointer"
            id={'1'}
            style={{color: active==="home"?"#84C441":"#23496A"}}
            onClick={()=>
              navigate("/")
              }>Home</button>
            <button
              className="text-[0.9vw] uppercase text-[#23496A] cursor-pointer"
              id={'2'}
              style={{color: active==="recr"?"#84C441":"#23496A"}}
              onClick={()=>navigate("/services")}
              >
              Recruitment Services
            </button>
            <button
              className="text-[0.9vw] uppercase text-[#23496A] cursor-pointer"
              id={'3'}
              style={{color: active==="wt"?"#FA54B2":"#23496A"}}
              onClick={()=>navigate("/wterritory")}>
              Women's Territory
            </button>
            <button
              className="pr-10 text-[0.9vw] uppercase text-[#23496A] cursor-pointer"
              id={'4'}
              style={{color: active==="about"?"#84C441":"#23496A"}}
              onClick={()=>navigate("/about")}>
              Know more
            </button>
          </div>
          <button className={loginStyle}
            onClick={()=>navigate("/comingsoon")}>
            Login
            <BiChevronDown className="w-7 h-7 " />
          </button>
          <button className={styles} onClick={()=>navigate("/comingsoon")}>Sign Up Now</button>
          <GiHamburgerMenu
            onClick={() => setIsOpen(!isOpen)}
            className="w-8 h-8 text-gray-600 lg:hidden "
          />
        </div>
      </div>
      {isOpen && <NavMenu setIsOpen={setIsOpen} />}
    </>
  );
};

export default Navbar;
