import React,{useState,useEffect} from 'react';
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { propTypes } from 'react-bootstrap/esm/Image';

const ClientLogos = (props) => {
    
    const [logos, setLogos] = useState([]);

    useEffect(() => {
      getLogos();
    }, []);
  
    const handleDragStart = (e) => e.preventDefault();
  
    const getLogos = async () => {
      let url = "https://jobsterritorybackend.herokuapp.com/clientLogos";
      let data = await fetch(url);
      let parsedData = await data.json();
      setLogos(parsedData);
    };

    const responsive = {
        0: { items: 4 },
        568: { items: 4 },
      };

    const clientLogos = logos.map((logo) => {
        return (
            <img
              src={logo.clientLogo}
              alt={logo.name}
              onDragStart={handleDragStart}
              role="presentation"
              className="clientLogo"
            />
        );
      });

  return (
        <AliceCarousel
          mouseTracking
          responsive={responsive}
          items={clientLogos}
          autoPlay={true}
          autoPlayInterval={800}
          infinite={true}
          disableDotsControls={true}
          disableButtonsControls={true}
          autoPlayDirection={props.dir}
        />
  )
}

export default ClientLogos;