import React from 'react';
import jt from '../../assets/jt-logo.png';
import fb from '../../assets/fb.png';
import insta from '../../assets/insta.png';
import link from '../../assets/link.png';
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Footer() {
    const navigate = useNavigate();
    const [subscribeEmail,setSubscribeEmail] = React.useState("");

    const handleSubscribe = async() => {
        await axios.post("https://jobs-territory-api.herokuapp.com/jobsterritory/subscribe",{
            email:subscribeEmail
        })
        .then((response)=>alert(response.data.data))
        .catch((err)=>console.error(err))
    }
  return (
    <div className='flex flex-col bg-[#1E1E1E] items-center overflow-hidden'>
        <div className='w-[100vw] flex flex-col items-center' style={{background: 'rgba(211, 219, 225, 0.5)'}}>
            <img src={jt} className='mt-2 mb-2 w-12vw h-14vh' />
        </div>
        <button className='text-white font-bold text-[20px] mt-20' onClick={() => {
                navigate("/")}}>HOME</button>
            <span className='h-[1px] bg-[#84C441] w-full flex content-center'>
            <button className='ml-[49%] -my-1' onClick={() => {
                navigate("/")}}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7.78008" cy="7.78008" r="7.78008" fill="#84C441"/>
                    <path d="M1.86719 5.60168L7.78005 11.5145L13.6929 5.60168" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </button>
            </span>
        <button className='text-white font-bold text-[20px] mt-16' onClick={() => {navigate("/services")}}>RECRUITMENT SERVICES</button>
            <span className='h-[1px] bg-[#84C441] w-full flex content-center'>
            <button className='ml-[49%] -my-1' onClick={() => {navigate("/services")}}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7.78008" cy="7.78008" r="7.78008" fill="#84C441"/>
                    <path d="M1.86719 5.60168L7.78005 11.5145L13.6929 5.60168" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </button>
            </span>
        <button className='text-white font-bold text-[20px] mt-16'  onClick={() => {
                navigate("/wterritory");
              }}>WOMEN'S TERRITORY</button>
            <span className='h-[1px] bg-[#84C441] w-full flex content-center'>
            <button className='ml-[49%] -my-1'  onClick={() => {
                navigate("/wterritory");
              }}>
               <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7.78008" cy="7.78008" r="7.78008" fill="#84C441"/>
                    <path d="M1.86719 5.60168L7.78005 11.5145L13.6929 5.60168" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </button>
            </span>
        <button className='text-white font-bold text-[20px] mt-16' onClick={() => {
                navigate("/about");
              }}>KNOW MORE</button>
            <span className='h-[1px] bg-[#84C441] w-full flex content-center'>
            <button className='ml-[49%] -my-1' onClick={() => {
                navigate("/about");
              }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7.78008" cy="7.78008" r="7.78008" fill="#84C441"/>
                    <path d="M1.86719 5.60168L7.78005 11.5145L13.6929 5.60168" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </button>
            </span>
        <p className='text-white font-bold text-[20px] mt-20'>Subscribe to our newsletter</p>
        <div className='bg-white mt-4 w-[90vw] h-[3.5rem] rounded-lg'>
            <input type='email' className='outline-none border-none text-xl h-5/6 ml-2 mt-1 w-11/12' placeholder='Mail' onChange={(e)=>setSubscribeEmail(e.target.value)} />
        </div>
        <button type='submit' className='bg-[#84C441] text-white text-[20px] p-1 w-32 mt-7 rounded-lg' onClick={handleSubscribe}>SUBMIT</button>
        <div className='text-white text-[24px] leading-[36px] mt-20 flex flex-col content-center'>
            <h1 className='self-center font-semibold'>Jobs Territory</h1>
            <div className='font-normal mt-10 flex flex-row text-center content-center'>
                <svg width="60" height="60" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className='ml-8'>
                    <path d="M11 0C8.08368 0.00344047 5.28779 1.16347 3.22563 3.22562C1.16348 5.28778 0.00345217 8.08367 1.17029e-05 11C-0.00348119 13.3832 0.774991 15.7018 2.21601 17.6C2.21601 17.6 2.51601 17.995 2.56501 18.052L11 28L19.439 18.047C19.483 17.994 19.784 17.6 19.784 17.6L19.785 17.597C21.2253 15.6996 22.0034 13.3821 22 11C21.9965 8.08367 20.8365 5.28778 18.7744 3.22562C16.7122 1.16347 13.9163 0.00344047 11 0ZM11 15C10.2089 15 9.43552 14.7654 8.77772 14.3259C8.11993 13.8863 7.60724 13.2616 7.30449 12.5307C7.00174 11.7998 6.92252 10.9956 7.07686 10.2196C7.2312 9.44371 7.61217 8.73097 8.17158 8.17156C8.73099 7.61215 9.44372 7.23119 10.2196 7.07685C10.9956 6.92251 11.7998 7.00172 12.5307 7.30447C13.2616 7.60722 13.8863 8.11992 14.3259 8.77771C14.7654 9.43551 15 10.2089 15 11C14.9987 12.0604 14.5768 13.0771 13.827 13.827C13.0771 14.5768 12.0605 14.9987 11 15Z" fill="#F8F8F8"/>
                </svg>
                <span className='mr-8 text-center text-[18px]'>1st floor, 110, Cambridge Rd,Ulsoor, Bengaluru, Karnataka 560008.</span>
            </div>
            <div className='font-normal mt-10 flex flex-row justify-center'>
                <svg width="22" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg" className='ml-2 mt-2'>
                    <path d="M25 5.27031V16.4062C25 17.4423 24.5884 18.4358 23.8559 19.1684C23.1233 19.9009 22.1298 20.3125 21.0938 20.3125H3.90625C2.87025 20.3125 1.87668 19.9009 1.14411 19.1684C0.41155 18.4358 0 17.4423 0 16.4062V5.27031L12.1031 12.3922C12.2234 12.4631 12.3604 12.5005 12.5 12.5005C12.6396 12.5005 12.7766 12.4631 12.8969 12.3922L25 5.27031ZM21.0938 4.83636e-08C22.0549 -0.000151119 22.9824 0.354073 23.6988 0.994906C24.4151 1.63574 24.8701 2.51819 24.9766 3.47344L12.5 10.8125L0.0234377 3.47344C0.129935 2.51819 0.584881 1.63574 1.30124 0.994906C2.0176 0.354073 2.94508 -0.000151119 3.90625 4.83636e-08H21.0938Z" fill="#F8F8F8"/>
                </svg>
                <span className='ml-4 text-center text-[18px]'>careers@jobsterritory.com</span>
            </div>
            <div className='font-normal mt-10 flex flex-row justify-center'>
                <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='mt-2 mr-4'>
                    <path d="M2.53204 0.482696L3.68492 0.104292C4.76601 -0.249912 5.92103 0.323195 6.3839 1.44301L7.30535 3.67383C7.70607 4.64515 7.48321 5.78806 6.75462 6.49867L4.72423 8.47649C4.84959 9.6601 5.23745 10.825 5.88675 11.9712C6.50333 13.0802 7.33039 14.0509 8.31894 14.8258L10.7576 13.9898C11.6811 13.6741 12.6883 14.0382 13.254 14.8929L14.5751 16.8839C15.2341 17.8783 15.1151 19.2489 14.2976 20.0915L13.4222 20.9946C12.5501 21.8933 11.304 22.2201 10.1522 21.8504C7.43071 20.9792 4.93102 18.3931 2.64883 14.0921C0.363431 9.78441 -0.44337 6.12796 0.2295 3.12713C0.512362 1.86431 1.38774 0.857801 2.53204 0.482696Z" fill="#F8F8F8"/>
                </svg>
                <span className='mr-4 text-center text-[18px]'>+91 9686116232</span>
            </div>
        </div>
        <p className='text-white text-[16px] mt-16'>Follow us on</p>
        <div className='flex flex-row content-center mt-4 mb-5'>
            <a className='mr-8' href='https://www.instagram.com/jobsterritory/' target='_blank'>
                <img src={insta} />
            </a>
            <a className='mr-8'
            href='https://www.facebook.com/jobsterritory/' target='_blank'>
                <img src={fb} />
            </a>
            <a className='' 
            href='https://www.linkedin.com/company/jobs-territory/' target='_blank'>
                <img src={link} />
            </a>
        </div>
        <p className='text-white text-center text-[14px] mb-10'>
            © Copyright Jobs Territory 2022. All right reserved.
        </p>
    </div>
  )
}

export default Footer;