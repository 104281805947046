import React from "react";

import img69 from "../media//image 69.png";
import img85 from "../media//image 85.png";
import img71 from "../media//image 71.png";
import img72 from "../media//image 72.png";
import img73 from "../media//image 73.png";
import img74 from "../media//image 74.png";
import img75 from "../media//image 75.png";
import img76 from "../media//image 76.png";

function IndustriesCater() {
  return (
    <div className="flex flex-col items-center py-0 space-y-5">
      <div className="h-3 bg-gradient-to-r w-full from-[#84C441] to-[#65D0CA] lg:hidden "></div>
      <div>
        <h1 className=" text-[#23496A] text-2xl font-bold lg:pt-6 ">
          INDUSTRIES WE CATER TO
        </h1>
      </div>
      <div className="grid grid-cols-2 pt-3 gap-x-16 gap-y-6 lg:grid-cols-4 lg:gap-x-40 lg:pt-10 lg:gap-y-16 lg:pb-4">
        <div className="flex flex-col items-center space-y-2">
          <img src={img69} alt="" />
          <h1>E-commerce</h1>
        </div>
        <div className="flex flex-col items-center space-y-2">
          <img src={img85} alt="" />
          <h1>Healthcare</h1>
        </div>
        <div className="flex flex-col items-center space-y-2 ">
          <img src={img71} alt="" />
          <h1>FMGC</h1>
        </div>
        <div className="flex flex-col items-center space-y-2">
          <img src={img72} alt="" />
          <h1>Hospitality</h1>
        </div>
        <div className="flex flex-col items-center space-y-2 ">
          <img src={img73} alt="" />
          <h1>E-learning</h1>
        </div>
        <div className="flex flex-col items-center space-y-2">
          <img src={img74} alt="" />
          <h1>
            Fashion & <br />
            Apparel
          </h1>
        </div>
        <div className="flex flex-col items-center space-y-2">
          <img src={img75} alt="" />
          <h1>
            Banking &<br />
            Insurance
          </h1>
        </div>
        <div className="flex flex-col items-center space-y-2">
          <img src={img76} alt="" />
          <h1>BPO-KPO</h1>
        </div>
      </div>
    </div>
  );
}

export default IndustriesCater;
