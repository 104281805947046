import React from 'react';
import jt from '../../assets/jt-logo.png';

const NavMenu = (props) => {
    console.log(props);
    return (
        <div className ='navMenu'>
            <img src={jt} className='navMenuLogo' />
            <button className='navMenuIcon' onClick={()=>{props.setMenu(false)}}>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L24.5 24.5M24.5 1L1 24.5" stroke="#C7C6C6" strokeWidth="2"/>
                </svg>
            </button>
            <div className='activeTab'>
                <h1 className='activeTabText'>Home</h1>
            </div>
            <button className='menuLink'>
                Recruitment Service
            </button>
            <button className='menuLink' style={{top: '335px', left: '125px'}}>
                Women’s Territory
            </button>
            <button className='menuLink' style={{top: '447px', left: '140px'}}>
                More About Us
            </button>
            <button className='signUp' style={{top: '570px', left: '148px'}}>
                SIGN UP NOW
            </button>
        </div>
      )
}

export default NavMenu;