import React from "react";
import logo from "../media/logogif.gif";
import { useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";

function NavMenu({ setIsOpen }) {
  let navigate = useNavigate();

  return (
    <div className="fixed h-screen top-0 flex flex-col w-full p-5 bg-[#12293D] z-50 overflow-y-hidden items-center">
      <MdClose
        className="self-end w-10 h-10 text-gray-400 "
        onClick={() => setIsOpen(false)}
      />
      <img src={logo} alt="" className="w-28 h-24" />
      <h1
        onClick={() => {
          navigate("/");
        }}
        className="mt-20 text-2xl font-semibold text-white"
      >
        Home
      </h1>
      <h1
        onClick={() => {
          navigate("/services");
        }}
        className="mt-20 text-2xl font-semibold text-white"
      >
        Recruitment Services
      </h1>
      <h1
        className="mt-20 text-2xl font-semibold text-white"
        onClick={() => {
          navigate("/wterritory");
        }}
      >
        Women's Territory
      </h1>
      <h1
        className="mt-20 text-2xl font-semibold text-white"
        onClick={() => {
          navigate("/about");
        }}
      >
        More About Us
      </h1>
      {/* <button className="uppercase bg-[#84C441] px-4 rounded-xl mt-24 text-white py-2 ">
        Sign Up Now
      </button> */}
    </div>
  );
}

export default NavMenu;
